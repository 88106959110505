import Vuex from 'vuex'
import Vue from 'vue'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
    state: {

        logged: false, //accesso effettuato

        partition: '', //partizione corrente

        oratore: null, //oratore connesso

        congregazione: null, //congregazione connesso

        idCircoscrizione: null, //idCircoscrizione corrente
        circoscrizione: null, //circoscrizione corrente
        idCongregazione: null, //congregazione corrente
        congregazioneCorrente: null, //congregazione corrente

        externalCirc: false, // circoscrizione extra selezionata

        externalCong: false, // congregazione extra selezionata

        listaProgrammi: []
    },  

    mutations: {
        logout(state) {          
            state.logged = false;
        },
        login(state) {           
            state.logged = true;
        }
    },

    actions: { 
        logout(context) {           
            context.commit('logout')           
        },
        login(context) {
            context.commit('login')
        }
    }
});