<template>
    <v-container>
        <v-card :loading="loading">
            <v-card-title>
                <b>Circoscrizione</b>
            </v-card-title>
            <v-card-text>
                <v-form v-model="valid" ref="form">
                    <v-row>
                        <v-col cols="2">
                            <v-text-field v-model="circoscrizione.codice"
                                          outlined
                                          label="Codice"
                                          :rules="[v=> !!v || 'campo obbligatorio']"
                                          dense>
                            </v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field v-model="circoscrizione.nome"
                                          outlined
                                          label="Nome Circoscrizione"
                                          :rules="[v=> !!v || 'campo obbligatorio']"
                                          dense>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="4">
                            <v-text-field v-model="circoscrizione.sorvegliante"
                                          outlined
                                          label="Nome Sorvegliante di Circoscrizione"
                                          dense>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="4">
                            <v-switch v-model="circoscrizione.prenotazione"
                                      hint="Attiva prenotazioni"
                                      persistent-hint
                                      inset
                                      /> 

                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn :loading="loadingSave" :disabled="!valid" color="error" @click="save">Salva</v-btn>
            </v-card-actions>
        </v-card>
        <v-snackbar v-model="snackSuccess"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackInfo"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Credenziali copiate in memoria.
        </v-snackbar>
        <v-snackbar v-model="snackError"
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel"
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote"
                    timeout="2000"
                    color="info">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>
    </v-container>
</template>


<script>import {callService, callPost, euro, gLocalData, gCheckData, gServerData, gDataCalendar, Snack, SUPERADMIN_CODE} from '@/modules/utilities.js'
    import { bus } from '@/main.js'

    export default {
       
        data: () => ({         
            SUPERADMIN_CODE: '',
            circoscrizione: {},
            valid: false,
            loading: false,
            loadingSave: false,
            snackInfo: false,
            snackSuccess: false,
            snackError: false,
            snackCancel: false,
            snackRemote: false,
        }),
        computed: {
        },
        methods: {
            async save() {
                this.loadingSave = true;
                let paramJson = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.oratore.codice,
                    token: this.$store.state.oratore.token,
                    idCircoscrizione: this.$store.state.idCircoscrizione,
                    circoscrizione: this.circoscrizione,
                };
                let circoscrizione = await callPost('api/circoscrizione/saveCircoscrizione', paramJson);

                this.snackError = !circoscrizione;
                this.snackSuccess = !this.snackError;
                this.loadingSave = false;

                if (circoscrizione) {
                    //ricarico
                    this.circoscrizione = circoscrizione;
                    this.$store.state.circoscrizione =JSON.parse(JSON.stringify(circoscrizione));
                }
            }, 
        },
        async created() {
            this.loading = true;
            let param = [this.$store.state.idCircoscrizione];
            this.circoscrizione = await callService("api/circoscrizione", param);
            this.loading = false;
        }        

    }</script>
