<template>
    <v-container>
        <template>
            <v-data-table :loading="loading"
                          :headers="headers"
                          :items="programmi"
                          :items-per-page="8"
                          loading-text="Caricamento dati..."
                          no-data-text="Nessuno appuntamento presente"
                          no-results-text="Nessuno appuntamento corrispondente ai criteri di ricerca"
                          :footer-props="{
                            'items-per-page-text':'Pagina da',
                            'items-per-page-all-text': 'Tutti',
                            'items-per-page-options':[5,8,10,20,-1]
                            }"
                          class="elevation-1">
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-col cols="2">
                            <v-radio-group :disabled="idOratore!=0" class="mt-6" @change="changeTipo" v-model="interno">
                                <v-radio label="Interni"
                                         value="1">
                                </v-radio>
                                <v-radio :label="'Esterni'+(idOratoreFix ? ' personali':'' )"
                                         value="2">
                                </v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col v-if="!idOratoreFix" cols="3">
                            <v-select v-model="idOratore"
                                      class="mt-8"
                                      :items="oratori"
                                      item-id="orId"
                                      item-value="orId"
                                      item-text="nominativo"
                                      dense
                                      @change="changeOratore"
                                      no-data-text="Nessun oratore presente"
                                      no-results-text="Nessun oratore presente"
                                      label="Oratori"
                                      outlined>
                            </v-select>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field v-model="dataInizio"
                                          class="mt-8"
                                          dense
                                          outlined
                                          @change="filtra"
                                          label="Data iniziale"
                                          type="date">
                            </v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field v-model="dataFine"
                                          class="mt-8"
                                          dense
                                          outlined
                                          @change="filtra"
                                          label="Data finale"
                                          type="date">
                            </v-text-field>
                        </v-col>
                        <v-tooltip v-if="!filePdf" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :loading="loadingPdf"
                                       :disabled="interno!=1"
                                       icon x-large
                                       color="pink"
                                       v-bind="attrs"
                                       v-on="on"
                                       @click="programmaPdf">
                                    <v-icon>mdi-file-pdf-box</v-icon>
                                </v-btn>
                            </template>
                            <span>Genera Programma PDF</span>
                        </v-tooltip>
                        <v-tooltip v-if="filePdf" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :loading="loadingPdf"
                                       icon x-large
                                       color="error"
                                       v-bind="attrs"
                                       v-on="on"
                                       @click="visualizzaPdf">
                                    <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                            </template>
                            <span>Visualizza Programma PDF</span>
                        </v-tooltip>
                        <v-tooltip v-if="!fileExcel" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :loading="loadingExcel"
                                       icon x-large
                                       color="success"
                                       v-bind="attrs"
                                       v-on="on"
                                       @click="programmaExcel">
                                    <v-icon>mdi-microsoft-excel</v-icon>
                                </v-btn>
                            </template>
                            <span>Genera Programma Excel</span>
                        </v-tooltip>
                        <v-tooltip v-if="fileExcel" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :loading="loadingExcel"
                                       icon x-large
                                       color="success"
                                       v-bind="attrs"
                                       v-on="on"
                                       @click="visualizzaExcel">
                                    <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                            </template>
                            <span>Scarica Programma Excel</span>
                        </v-tooltip>
                    </v-toolbar>
                </template>
                <template v-slot:item.data="{ item }">
                    <span v-text="localData(item.data)" />
                </template>
                <template v-slot:item.congregazioneEffettiva="{ item }">
                    <span v-if="idOratore=='0' && interno=='1' && item.isStandard && item.congregazioneEsterna">{{item.congregazioneEsterna.nome}}</span>
                    <span v-if="idOratore=='0' && interno=='1' && item.isExtra && item.congregazioneExtra">{{item.congregazioneExtra}}</span>
                    <span v-if="idOratore=='0' && interno=='1' && item.isStandard && !item.congregazioneEsterna">{{item.congregazione.nome}}</span>
                    <span v-if="idOratore=='0' && interno=='2' && item.isStandard && item.congregazione">{{item.congregazione.nome}}</span>
                    <span v-if="idOratore=='0' && interno=='2' && item.isExtra && item.congregazioneExtra">{{item.congregazioneExtra}}</span>
                    <span v-if="idOratore!='0' && item.isStandard">{{item.congregazione.nome}}</span>
                    <span v-if="idOratore!='0' && item.isExtra">{{item.congregazioneExtra}}</span>
                    <span v-if="item.isVisita"><strong>Visita del Sorvegliante</strong></span>
                    <span v-if="item.isSpeciale"><strong>Evento Speciale</strong></span>
                    <span v-if="item.isCongresso"><strong>Assemblea/Congresso</strong></span>
                </template>
                <template v-slot:item.oratoreEffettivo="{ item }">
                    <span v-if="item.oratore">{{item.oratore.nominativo}}</span>
                    <span v-if="item.oratoreExtra">{{item.oratoreExtra}}</span>
                    <span v-if="item.isVisita">{{item.sorvegliante}}</span>
                </template>
                <template v-slot:item.temaEffettivo="{ item }">
                    <span v-if="item.discorso && item.discorso.diId==99999">( - ) {{item.discorso.tema}}</span>
                    <span v-if="item.discorso && item.discorso.diId!=99999">({{item.discorso.diId}}) {{item.discorso.tema}}</span>
                    <span v-if="item.isVisita">{{item.teamVisita}}</span>
                </template>
            </v-data-table>
        </template>
    </v-container>
</template>

<script>

    import {callService, callPost, euro, gLocalData, gCheckData, gServerData, gDataCalendar, Snack, SUPERADMIN_CODE} from '@/modules/utilities.js'
    import { bus } from '@/main.js'

    export default {        
        data: () => ({
             headers: [
                { text: 'Data', value: 'data' },
                { text: 'Congregazione', value: 'congregazioneEffettiva', sortable: false },
                { text: 'Oratore', value: 'oratoreEffettivo',sortable: false },
                { text: 'Tema', value: 'temaEffettivo',sortable: false }
            ],            
            programmi: [],
            oratori: [],
            idOratore: '0',
            idOratoreFix: '',
            loading: false,
            interno: '1' ,
            dataInizio: new Date(),
            dataFine: new Date(),
            loadingPdf: false,
            filePdf:'',
            loadingExcel: false,
            fileExcel:'',
        }),
        computed: {
        },
        methods: {
            async programmaPdf() {
                this.loadingPdf=true;
                let paramJson = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.oratore.codice,
                    token: this.$store.state.oratore.token,
                    idCircoscrizione: this.$store.state.idCircoscrizione,
                    idCongregazione: this.$store.state.idCongregazione,
                    interno: this.interno=='1',
                    idOratore: this.idOratoreFix && this.interno=='2'  ? this.idOratoreFix : this.idOratore,
                    dataInizio: this.dataInizio,
                    dataFine: this.dataFine,
                    stampa: true,
                    pdf: true
                };
                let outputPdf = await callPost('api/programma/ricerca', paramJson);
                if(outputPdf) {
                    this.filePdf = outputPdf.fileOutput;
                }
                else this.snackError=true;
                this.loadingPdf=false;
            },
            visualizzaPdf(){
                window.open(this.filePdf);
                this.filePdf='';
            },    
            async programmaExcel() {
                this.loadingExcel=true;
                let paramJson = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.oratore.codice,
                    token: this.$store.state.oratore.token,
                    idCircoscrizione: this.$store.state.idCircoscrizione,
                    idCongregazione: this.$store.state.idCongregazione,
                    interno: this.interno=='1',
                    idOratore: this.idOratoreFix && this.interno=='2'  ? this.idOratoreFix : this.idOratore,
                    dataInizio: this.dataInizio,
                    dataFine: this.dataFine,
                    stampa: true,
                    pdf: false
                };
                let outputExcel = await callPost('api/programma/ricerca', paramJson);
                if (outputExcel) {
                    this.fileExcel = outputExcel.fileOutput;
                }
                else this.snackError=true;
                this.loadingExcel=false; 
            },
            visualizzaExcel(){
                window.open(this.fileExcel);
                this.fileExcel='';
            },
            localData(v) {
                return gLocalData(v);
            },
            dataCalendar(v) {
                return gDataCalendar(v);
            },
            async changeTipo(){
                this.idOratore ='0';
                await this.filtra();
            },
            async changeOratore(){
                if(this.idOratore!='0') this.interno = '0' ;
                if(this.idOratore=='0') this.interno = '1' ;
                await this.filtra();
            },
            async filtra() {
                this.loading = true;
                let paramJson = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.oratore.codice,
                    token: this.$store.state.oratore.token,
                    idCircoscrizione: this.$store.state.idCircoscrizione,
                    idCongregazione: this.$store.state.idCongregazione,
                    interno: this.interno=='1',
                    idOratore: this.idOratoreFix && this.interno=='2'  ? this.idOratoreFix : this.idOratore,
                    dataInizio: this.dataInizio,
                    dataFine: this.dataFine
                };
                this.programmi = await callPost('api/programma/ricerca', paramJson);
                let sorv = this.$store.state.circoscrizione.sorvegliante;
                this.programmi = this.programmi.map(p => ({...p, sorvegliante: p.isVisita ? sorv:'' }));
                this.loading = false;
            }
        },        
        async created() {

            let param = [this.$store.state.idCircoscrizione,this.$store.state.idCongregazione];
            this.oratori = await callService("api/oratore/oratoriCongregazione", param);
            this.oratori.unshift( {orId:'0', nominativo:'<Tutti>' } );

            this.dataInizio = this.dataCalendar(this.dataInizio.toISOString());
            let anno = this.dataFine.getFullYear();
            this.dataFine.setFullYear(anno+1);
            this.dataFine = this.dataCalendar(this.dataFine.toISOString());

            if(this.$store.state.oratore.codice!=SUPERADMIN_CODE
            && !this.$store.state.oratore.isAdmin
            && !this.$store.state.oratore.isCoordinatore
            && !this.$store.state.oratore.isAssistente) {
                this.idOratoreFix = this.$store.state.oratore.orId;
            }
            await this.filtra();
        },
        beforeDestroy() {
        }
        
    }</script>
