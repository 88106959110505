<template>
    <v-container>
        <v-row>
            <v-col cols="4">
                <v-radio-group class="my-0" @change="checkTipoProgramma" v-model="tipoProgramma"
                               row>
                    <v-radio label="Programma Interno"
                             value='0'></v-radio>
                    <v-radio :label="'Programma Esterno'+personale"
                             value='1'></v-radio>
                </v-radio-group>
            </v-col>
            <v-col cols="1" align="right">
                <v-btn icon
                       @click="$refs.calendar.prev()">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="2" align="center">
                <v-toolbar-title class="mt-1" v-if="$refs.calendar">
                    <b>{{ $refs.calendar.title }}</b>
                </v-toolbar-title>
            </v-col>
            <v-col cols="1" align="left">
                <v-btn icon
                       @click="$refs.calendar.next()">
                    <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="4" align="right">
                <v-btn color="success" :loading="loading"
                       @click="value=new Date()">
                    Oggi
                    <v-icon right>mdi-calendar</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="fill-height">
            <v-col>
                <v-sheet height="500">

                    <v-calendar ref="calendar"
                                :start="dataStart"
                                v-model="value"
                                color="primary"
                                type="month"
                                :events="eventi"
                                event-color="blue"
                                :event-ripple="false"
                                :event-more="false"
                                :weekdays="weekday"
                                event-overlap-mode="column"
                                :event-overlap-threshold="30"
                                :month-format="monthformat"
                                :weekday-format="weekdayformat"
                                :interval-format="intervalformat"
                                :first-interval=8
                                :interval-count=11
                                @click:event="clickEvent"
                                @click:date="clickDate">

                        <template v-slot:event="{ event }">
                            <span align="center" class="mt-0">
                                <span v-if="event.programma.oratore" class="ml-2 text-caption">
                                    {{event.programma.oratore.nominativo}}
                                </span>
                                <span v-if="event.programma.isExtra" class="ml-2 text-caption">
                                    {{event.programma.oratoreExtra }}
                                </span>
                                <span v-if="event.programma.isCongresso" class="ml-2 text-caption">
                                    Assemblea/Congresso                                       
                                </span>
                                <span v-if="event.programma.isVisita" class="ml-2 text-caption">
                                    Visita del Sorvegliante
                                </span>
                                <span v-if="event.programma.isSpeciale" class="ml-2 text-caption">
                                    Evento Speciale
                                </span>
                            </span>
                        </template>
                    </v-calendar>
                </v-sheet>
            </v-col>
        </v-row>

        <v-snackbar v-model="snackSuccess"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackError"
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel"
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote"
                    timeout="2000"
                    color="info">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>

        <programma-dialog v-model="dialogProgramma" editOratore="oratore" />

    </v-container>
</template>

<script>

    import {callService, callPost, euro, gLocalData, gCheckData, gServerData, gDataCalendar, Snack, SUPERADMIN_CODE} from '@/modules/utilities.js'
    import ProgrammaDialog from '@/views/components/ProgrammaDialog.vue'
    import { bus } from '@/main.js'

    export default {
        components:{
            ProgrammaDialog
        },
        data: () => ({
            weekday: [1, 2, 3, 4, 5, 6, 0],
            value: '',
            type: 'week',
            loading: false,
            loadingSalva: false,
            loadingElimina: false,
            loadingAnnulla: false,
            dataStart: new Date(),
            eventi: [],
            tipoProgramma: '0',
            appuntamento: {},
            congregazioni:[],
            dialogAppuntamento: false,            
            valid: false,
            snackSuccess: false,
            snackCancel: false,
            snackError: false,
            snackRemote: false,
            dialogProgramma: false,
            personale: '' 
        }),
        computed: {
            headerDialog() {
                if(this.nuovo) return "Nuovo Appuntamento";
                else return "Aggiorna Appuntamento";
            }
        },
        methods: {
            weekdayformat(d) {
                if (d.weekday == 1) return "LUN";
                if (d.weekday == 2) return "MAR";
                if (d.weekday == 3) return "MER";
                if (d.weekday == 4) return "GIO";
                if (d.weekday == 5) return "VEN";
                if (d.weekday == 6) return "SAB";
                if (d.weekday == 0) return "DOM";
            },
            monthformat(d) {
                if (d.month == 1) return "Gen";
                if (d.month == 2) return "Feb";
                if (d.month == 3) return "Mar";
                if (d.month == 4) return "Apr";
                if (d.month == 5) return "Mag";
                if (d.month == 6) return "Giu";
                if (d.month == 7) return "Lug";
                if (d.month == 8) return "Ago";
                if (d.month == 9) return "Set";
                if (d.month == 10) return "Ott";
                if (d.month == 11) return "Nov";
                if (d.month == 12) return "Dic";
            },
            intervalformat(d) {
                return d.hour + ":00";
            },
            
            clickEvent(event) {
                this.visualizzaAppuntamento(event.eventParsed.input, false);
            },
            clickDate(date) {
                if (this.tipoProgramma=='0' && this.eventi.find(e => this.localData(e.programma.data) == this.localData(date.date))) return;
                this.visualizzaAppuntamento(null, true, date.date, date.future );
            },

            visualizzaAppuntamento(event, nuovo, data, futuro) {
                if (nuovo) {
                    let updateEnabled=
                        this.$store.state.oratore.isAdmin
                        ||
                        ((this.$store.state.oratore.isCoordinatore || this.$store.state.oratore.isAssistente)
                        && this.$store.state.oratore.congregazione.coId==this.$store.state.congregazioneCorrente.coId);                  
                    if(!updateEnabled) return;

                    let programma = {
                        data: data,
                        isStandard: true,
                        congregazione : {
                            nome: this.$store.state.congregazioneCorrente.nome
                        }
                    }
                    bus.$emit('dialog-show-programma', programma, this.congregazioni,this.tipoProgramma == '0', true, futuro);
                }
                else {
                    let programma = JSON.parse(JSON.stringify(event.programma));
                    bus.$emit('dialog-show-programma', programma, this.congregazioni, this.tipoProgramma == '0', false, false);
                }
            },            
            localData(v) {
                return gLocalData(v);
            },
            checkData(d) {                
                return gCheckData(d);
            },
            checkDataFutura(d) {
                let ok = gCheckData(d);
                if (!ok) return false;

                let adesso = new Date();
                let data = new Date(this.serverData(d));
                data.setDate(data.getDate() + 1);
                return data.getTime()>adesso.getTime(); 
            },
            serverData(stringDate) {                
                return gServerData(stringDate);
            },
            async checkTipoProgramma() {
                this.loading = true;
                await this.internalCreated(Snack.nothing);
                this.loading = false;
            },
            async internalCreated(snack) {

                let param = [this.$store.state.idCircoscrizione];                
                this.congregazioni = await callService("api/congregazione/lista", param);
                 
                param = [this.$store.state.idCircoscrizione, this.$store.state.idCongregazione];  
                let tipo = this.tipoProgramma == '0' ? 'interno' : 'esterno';
                const data = await callService("api/programma/" + tipo, param);

                let eventi = [];
                data.forEach(function (programma) {

                    eventi.push({
                        name: programma.data,
                        start: new Date(programma.data),
                        color: programma.isProvvisorio ? "orange" : programma.isStandard || programma.isExtra ? "teal":"deep-orange darken-4",
                        timed: false,

                        //campi personalizzati

                        programma: programma
                    });
                });                

                this.eventi = eventi;

                this.snackSuccess = (snack == Snack.success);
                this.snackError = (snack == Snack.error);
                this.snackCancel = (snack == Snack.cancel);
                this.snackRemote = (snack == Snack.remote);
            }
        },
        async created() {
            this.loading = true;
            await this.internalCreated(Snack.nothing);
            if(this.$store.state.oratore.codice!=SUPERADMIN_CODE
                && !this.$store.state.oratore.isAdmin
                && !this.$store.state.oratore.isCoordinatore
                && !this.$store.state.oratore.isAssistente) {
                        this.personale = ' personale';  
            }
            this.loading = false;
            var vm = this;

            bus.$on('remove-programma', function (idProgramma) {              
                let idxEvento = vm.eventi.findIndex(e => e.programma.id == idProgramma);
                vm.eventi.splice(idxEvento, 1);
            });

            bus.$on('update-programma', function (programma) {              
                let idxEvento = vm.eventi.findIndex(e => e.programma.id == programma.id);
                let evento ={
                    name: programma.data,
                    start: new Date(programma.data),
                    color: programma.isProvvisorio ? "orange" : programma.isStandard || programma.isExtra ? "teal":"deep-orange darken-4",
                    timed: false,

                    //campi personalizzati

                    programma: programma
                }
                vm.eventi.splice(idxEvento, 1, evento);
            });

            bus.$on('add-programma', function (programma) {              
                vm.eventi.push({
                        name: programma.data,
                        start: new Date(programma.data),
                        color: programma.isProvvisorio ? "orange" : programma.isStandard || programma.isExtra ? "teal":"deep-orange darken-4",
                        timed: false,

                        //campi personalizzati

                        programma: programma
                    });
            });

        },
        beforeDestroy() {
            bus.$off('remove-programma');
            bus.$off('update-programma');
            bus.$off('add-programma');
        }

    }</script>
