<template>
    <v-container>
        <template>
            <v-data-table :loading="loading"
                          :headers="headers"
                          :items="congregazioniEstese"
                          :expanded.sync="expanded"
                          item-key="congregazione.coId"
                          show-expand
                          :items-per-page="8"
                          loading-text="Caricamento dati..."
                          no-data-text="Nessuna congregazione presente"
                          no-results-text="Nessuna congregazione corrispondente ai criteri di ricerca"
                          :single-expand="true"
                          @item-expanded="expandCongregazione"
                          :footer-props="{
                            'items-per-page-text':'Pagina da',
                            'items-per-page-all-text': 'Tutti',
                            'items-per-page-options':[5,8,10,20,-1]
                            }"
                          :search="search"
                          class="elevation-1">
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title><b>Circoscrizione {{nomeCircoscrizione}}</b></v-toolbar-title>
                        <v-col cols="5">
                            <v-text-field class="mt-6 ml-4"
                                          v-model="search"
                                          append-icon="mdi-magnify"
                                          label="Ricerca una congregazione..."
                                          single-line
                                          outlined
                                          clearable
                                          dense>
                            </v-text-field>
                        </v-col>
                        <v-tooltip v-if="isCoordAss && !filePdf" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :loading="loadingPdf"
                                       icon x-large
                                       color="pink"
                                       v-bind="attrs"
                                       v-on="on"
                                       @click="elencoPdf">
                                    <v-icon>mdi-file-pdf-box</v-icon>
                                </v-btn>
                            </template>
                            <span>Genera Elenco PDF</span>
                        </v-tooltip>
                        <v-tooltip v-if="isCoordAss && filePdf" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :loading="loadingPdf"
                                       icon x-large
                                       color="error"
                                       v-bind="attrs"
                                       v-on="on"
                                       @click="visualizzaPdf">
                                    <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                            </template>
                            <span>Visualizza Elenco PDF</span>
                        </v-tooltip>
                        <v-tooltip v-if="isCoordAss && !fileExcel" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :loading="loadingExcel"
                                       icon x-large
                                       color="success"
                                       v-bind="attrs"
                                       v-on="on"
                                       @click="elencoExcel">
                                    <v-icon>mdi-microsoft-excel</v-icon>
                                </v-btn>
                            </template>
                            <span>Genera Elenco Excel</span>
                        </v-tooltip>
                        <v-tooltip v-if="isCoordAss && fileExcel" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :loading="loadingExcel"
                                       icon x-large
                                       color="success"
                                       v-bind="attrs"
                                       v-on="on"
                                       @click="visualizzaExcel">
                                    <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                            </template>
                            <span>Scarica Elenco Excel</span>
                        </v-tooltip>
                        <v-spacer></v-spacer>
                        <v-tooltip v-if="isAdmin" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn 
                                       icon x-large
                                       color="info"
                                       v-bind="attrs"
                                       v-on="on"
                                       @click="nuovaCongregazione">
                                    <v-icon>mdi-view-grid-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>Nuova Congregazione</span>
                        </v-tooltip>                        
                    </v-toolbar>
                </template>

                <template v-slot:item.congregazione.nome="{ item }">
                    <div>
                        <span class="text-subtitle font-weight-medium" v-text="item.congregazione.nome" />
                        <v-spacer></v-spacer>
                        <span class="font-weight-light" v-text="item.congregazione.indirizzo" />
                    </div>
                </template>
                <template v-slot:item.congregazione.localizzaione="{ item }">
                    <a v-if="item.congregazione.coordinate" target="_blank"
                       style="text-decoration: none;"
                       :href="'https://maps.google.it/?q='+item.congregazione.coordinate">
                        <v-icon>mdi-map-marker-outline</v-icon>
                    </a>
                </template>
                <template v-slot:item.congregazione.modifica="{ item }">
                    <v-icon v-if="modificheAbilitate(item)" @click="apriCongregazione(item)">mdi-magnify</v-icon>
                </template>
                <template v-slot:item.congregazione.admin="{ item }">
                    <div v-if="item.congregazione.coordinatore">
                        <span v-text="item.congregazione.coordinatore.nominativo" />
                        <v-spacer></v-spacer>
                        <span class="font-weight-light" v-text="item.congregazione.coordinatore.telefono" />
                        <v-spacer></v-spacer>
                        <v-chip v-if="item.congregazione.coordinatore.isAnziano" class="mr-2" x-small dark color="teal">A</v-chip>
                        <v-chip v-if="item.congregazione.coordinatore.isSM" class="mr-2" x-small dark color="teal">SM</v-chip>
                        <v-chip v-if="item.congregazione.coordinatore.isAssistente" class="mr-2" x-small dark color="grey">ASSISTENTE</v-chip>
                        <v-chip v-if="item.congregazione.coordinatore.isCoordinatore" class="mr-2" x-small dark color="deep-orange darken-4">ADMIN</v-chip>
                        <v-chip v-if="item.congregazione.coordinatore.isAdmin" class="mr-2" x-small dark color="orange">APP ADMIN</v-chip>
                    </div>
                </template>
                <template v-slot:item.congregazione.giorno="{ item }">
                    <div>
                        <span class="text-subtitle" v-text="item.congregazione.giorno" />
                        <br />
                        <span class="font-weight-light" v-text="item.congregazione.ora" />
                    </div>
                </template>
                <template v-slot:item.seleziona="{ item }">
                    <v-btn v-if="modificheAbilitate(item)" @click="selezionaCong(item)" small color="info">Seleziona</v-btn>
                </template>
                <template v-slot:item.data-table-expand="{item, expand, isExpanded}">
                    <div v-if="
                          $store.state.oratore.codice==SUPERADMIN_CODE
                          ||
                          ($store.state.oratore._partition==$store.state.idCircoscrizione
                           && (
                                $store.state.oratore.isAdmin
                            || $store.state.oratore.isCoordinatore
                            || $store.state.oratore.isAssistente
                            ||  $store.state.oratore.congregazione.coId==item.congregazione.coId
                              )
                          )">
                        <v-icon v-if="!isExpanded" @click="expand(true)">mdi-chevron-down</v-icon>
                        <v-icon v-if="isExpanded" @click="expand(false)">mdi-chevron-up</v-icon>
                    </div>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td></td>
                    <td colspan="4">
                        <v-card>
                            <v-data-table class="my-2"
                                          :loading="loadingOratori"
                                          :headers="headersOratori"
                                          :items="oratoriEstesi"
                                          :items-per-page="100"
                                          loading-text="Caricamento dati..."
                                          no-data-text="Nessun oratore presente"
                                          hide-default-header
                                          hide-default-footer>
                                <template v-slot:body.prepend>
                                    <tr>
                                        <th class="text-subtitle">Elenco oratori</th>
                                        <th class="title"></th>
                                        <th class="title"></th>
                                        <th class="title">
                                            <v-icon v-if="modificheAbilitate(item)" @click="clickOratore()" class="mr-2">mdi-plus</v-icon>
                                        </th>
                                    </tr>
                                </template>
                                <template v-slot:item.oratore.cognome="{ item }">
                                    <span v-text="item.oratore.nome+' '+item.oratore.cognome" />
                                    <v-spacer></v-spacer>
                                    <v-chip v-if="item.oratore.isAnziano" class="mr-2" x-small dark color="teal">A</v-chip>
                                    <v-chip v-if="item.oratore.isSM" class="mr-2" x-small dark color="teal">SM</v-chip>
                                    <v-chip v-if="item.oratore.isAssistente" class="mr-2" x-small dark color="darkgrey">ASSISTENTE</v-chip>
                                    <v-chip v-if="item.oratore.isCoordinatore" class="mr-2" x-small dark color="deep-orange darken-4">ADMIN</v-chip>
                                    <v-chip v-if="item.oratore.isAdmin" class="mr-2" x-small dark color="orange">APP ADMIN</v-chip>
                                </template>
                                <template v-slot:item.oratore.telefono="{ item }">
                                    <div>
                                        <span class="text-subtitle" v-text="item.oratore.telefono" />
                                        <br />
                                        <span class="font-weight-light" v-text="item.oratore.mail" />
                                    </div>
                                </template>
                                <template v-slot:item.numeroSchemi="{ item }">
                                    <div>
                                        <span class="text-subtitle" v-if="item.numeroSchemi>1">
                                            {{item.numeroSchemi}} schemi
                                        </span>
                                        <span class="text-subtitle" v-if="item.numeroSchemi==0">
                                            nessuno schema
                                        </span>
                                        <span class="text-subtitle" v-if="item.numeroSchemi==1">
                                            1 schema
                                        </span>
                                    </div>
                                </template>
                                <template v-slot:item.dettaglio="{ item }">
                                    <div v-if="
                                          $store.state.oratore.codice==SUPERADMIN_CODE
                                          ||
                                          ($store.state.oratore._partition==$store.state.idCircoscrizione
                                           && (
                                                $store.state.oratore.isAdmin
                                             || $store.state.oratore.isCoordinatore
                                             || $store.state.oratore.isAssistente
                                             || $store.state.oratore.orId==item.oratore.orId
                                              )
                                          )">
                                        <v-icon @click="clickOratore(item)">mdi-magnify</v-icon>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-card>
                    </td>
                </template>
            </v-data-table>

            <oratore-dialog v-model="dialogOratore" editOratore="oratore" />

            <template>
                <v-dialog v-model="dialog" persistent max-width="650px">
                    <v-card class="elevation-24 rounded-lg">
                        <v-card-title></v-card-title>
                        <v-card-text>
                            <v-form :readonly="!modificheAbilitate" v-model="valid" ref="formCongregazione">
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field v-model="congregazione.nome"
                                                      outlined
                                                      label="Nome"
                                                      :rules="[v=> !!v || 'campo obbligatorio']"
                                                      dense>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field v-model="congregazione.indirizzo"
                                                      outlined
                                                      label="Indirizzo"
                                                      dense>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <v-text-field v-model="congregazione.giorno"
                                                      outlined
                                                      label="Giorno adunanza pubblica"
                                                      dense>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="congregazione.ora"
                                                      outlined
                                                      label="Orario"
                                                      dense>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field v-model="congregazione.latitudine"
                                                      outlined
                                                      label="Latitudine"
                                                      :rules="[v=> v==null ||v.length==0 ||  /^[0-9]{1,3}\.[0-9]{1,6}$/.test(v) || 'esempio 37.12345']"
                                                      hint="esempio 37.12345 (sito jw.org)"
                                                      persistent-hint
                                                      dense>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field v-model="congregazione.longitudine"
                                                      outlined
                                                      label="Longitudine"
                                                      :rules="[v=> v==null ||v.length==0 ||  /^[0-9]{1,3}\.[0-9]{1,6}$/.test(v) || 'esempio 17.12345']"
                                                      hint="esempio 17.12345 (sito jw.org)"
                                                      persistent-hint
                                                      dense>
                                        </v-text-field>
                                    </v-col>
                                </v-row>                               
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn :loading="loadingCong" v-if="modificheAbilitate" :disabled="!valid" color="error" @click="save">Salva</v-btn>
                            <v-btn color="warning" @click.stop="dialog = false">Chiudi</v-btn>
                            <v-switch class="ml-4" v-if="prenotazioniAttive" :disabled="!modificheAbilitate" inset hint="prenotazioni" persistent-hint v-model="congregazione.prenotazione"></v-switch>
                            <v-spacer></v-spacer>
                            <div>
                                <v-btn :disabled="!valid" target="_blank" color="success"
                                       :href="'https://maps.google.it/?q='+congregazione.latitudine+','+congregazione.longitudine">
                                    <v-icon>mdi-map-marker-outline</v-icon>
                                    test coordinate
                                </v-btn>
                            </div>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>
            <v-snackbar v-model="snackSuccess"
                        timeout="2000"
                        color="success">
                <v-icon dark>
                    mdi-checkbox-marked-circle
                </v-icon>
                Operazione eseguita con successo.
            </v-snackbar>
            <v-snackbar v-model="snackInfo"
                        timeout="2000"
                        color="success">
                <v-icon dark>
                    mdi-checkbox-marked-circle
                </v-icon>
                Credenziali copiate in memoria.
            </v-snackbar>
            <v-snackbar v-model="snackError"
                        timeout="2000"
                        color="error">
                <v-icon dark>
                    mdi-alert-circle
                </v-icon>
                Errore durante l'esecuzione dell'operazione.
            </v-snackbar>
            <v-snackbar v-model="snackCancel"
                        timeout="2000"
                        color="warning">
                <v-icon dark>
                    mdi-alert-circle
                </v-icon>
                Modifiche annullate.
            </v-snackbar>
            <v-snackbar v-model="snackRemote"
                        timeout="2000"
                        color="info">
                <v-icon dark>
                    mdi-alert-circle
                </v-icon>
                Aggiornamento remoto.
            </v-snackbar>
            
        </template>
    </v-container>
</template>

<script>

    import {callService, callPost, euro, gLocalData, gCheckData, gServerData, gDataCalendar, Snack, SUPERADMIN_CODE} from '@/modules/utilities.js'
    import OratoreDialog from '@/views/components/OratoreDialog.vue'
    import { bus } from '@/main.js'

    export default {
        components:{
            OratoreDialog
        },
        data: () => ({
             headers: [
                { text: '', value: 'data-table-expand' },
                { text: 'Congregazione', value: 'congregazione.nome' },
                { text: 'Oratori esterni', value: 'numeroOratori',sortable:false },
                { text: 'Admin', value: 'congregazione.admin',sortable:false },
                { text: 'Giorno', value: 'congregazione.giorno',sortable:false },
                { text: 'Localizzazione', value: 'congregazione.localizzaione',sortable:false },
                { text: '', value: 'congregazione.modifica',sortable:false },
                { text: '', value: 'seleziona', sortable: false },
            ],
            headersOratori: [
                {
                    text: 'Nominativo',
                    align: 'start',
                    value: 'oratore.cognome',
                },
                { text: 'Telefono/Mail', value: 'oratore.telefono', sortable: false },
                { text: 'Schemi', value: 'numeroSchemi', sortable: false },
                { text: '', value: 'dettaglio', sortable: false },
            ],
            congregazioniEstese: [],
            congregazione: {},
            dialog: false,
            valid: false,
            oratoriEstesi: [],
            expanded: [],
            nomeCircoscrizione:'',
            dialogOratore: false,
            oratore: {},
            discorsi: [],
            listaDiscorsi: [],
            loadingOratori:false,
            loading: false,
            loadingCong: false,
            loadingPdf: false,
            filePdf:'',
            loadingExcel: false,
            fileExcel:'',
            snackInfo: false,
            snackSuccess: false,
            snackError: false,
            snackCancel: false,
            snackRemote: false,
            SUPERADMIN_CODE:0,
            search: '',
            prenotazioniAttive: false
        }),
        computed: {
            isAdmin() {
                let b=false;
                if(this.$store.state.oratore.codice==this.SUPERADMIN_CODE) b = true;
                else if(this.$store.state.oratore.isAdmin) b=true;
                return b;
            },
            isCoordAss() {
                let b=false;
                if(this.$store.state.oratore.codice==this.SUPERADMIN_CODE) b = true;
                else if(this.$store.state.oratore.isAdmin) b=true;
                else if(this.$store.state.oratore.isCoordinatore) b=true;
                else if(this.$store.state.oratore.isAssistente) b=true;
                return b;
            }
        },
        methods: {
            async clickOratore(oratoreEsteso) {
                if(!oratoreEsteso) {
                    bus.$emit('dialog-show-oratore',this.congregazione, {orId: "0", isAnziano: true},[], this.listaDiscorsi);
                }
                else {                
                    this.oratore=JSON.parse(JSON.stringify(oratoreEsteso.oratore));
                    let param=[this.$store.state.idCircoscrizione,this.oratore.orId];
                    let discorsi = await callService("api/oratore/discorsiOratore", param);
                    bus.$emit('dialog-show-oratore',this.congregazione, this.oratore, discorsi, this.listaDiscorsi);
                }
            },
            async onUpdateOratore({orId,coId,coIdUpd}) {
                const i = this.oratoriEstesi.findIndex(o => o.oratore.orId == orId);
                if(i>=0) {
                    let param = [this.$store.state.idCircoscrizione,false,coId,orId];
                    let oraExt = await callService("api/oratore/congregazioneOratore", param);
                    this.oratoriEstesi.splice(i, 1, oraExt[0]);
                }
                if(coIdUpd) {
                    let param = [this.$store.state.idCircoscrizione,coIdUpd];
                    let congExt = await callService("api/congregazione", param);
                    const j = this.congregazioniEstese.findIndex(c=>c.congregazione.coId==coIdUpd);
                    this.congregazioniEstese.splice(j, 1, congExt[0]);
                    await this.expandCongregazione({ item: congExt[0], value: true });
                }
            },
            async expandCongregazione({ item, value }) {
                if (value) {
                    this.loadingOratori=true;
                    this.congregazione = item.congregazione;
                    let interni =this.modificheAbilitate(item);
                    let param = [this.$store.state.idCircoscrizione, interni, item.congregazione.coId];
                    this.oratoriEstesi = await callService("api/oratore/congregazioneOratore", param);       
                    this.loadingOratori=false;
                }
                else this.oratori=[];
            },
            async selezionaCong(item) {
                    this.$store.state.idCongregazione=item.congregazione.coId;
                    this.$store.state.congregazioneCorrente=item.congregazione;
                    this.$store.state.externalCirc = true;
                    this.$store.state.externalCong = true;
                    this.$router.replace({ name: "programma" });
            },
            modificheAbilitate(item) {
                let b=false;
                if(this.$store.state.oratore.codice==this.SUPERADMIN_CODE) b = true;
                else if(this.$store.state.oratore.isAdmin) b=true;
                else if(this.$store.state.oratore.congregazione.coId==item.congregazione.coId && (this.$store.state.oratore.isCoordinatore || this.$store.state.oratore.isAssistente)) b = true;
                return b;
            },            
            nuovaCongregazione() {
                this.congregazione={ coId:"0" };
                this.dialog=true;
            },
            async apriCongregazione(item) {
                this.congregazione = JSON.parse(JSON.stringify(item.congregazione));
                let param = [this.$store.state.idCircoscrizione, this.congregazione.coId];
                let pre = await callService("api/congregazione/haPrenotazioni", param);
                this.congregazione.prenotazione = pre;
                this.dialog=true;
            },
            async elencoPdf() {
                this.loadingPdf=true;
                let param = [this.$store.state.idCircoscrizione];
                let outputPdf = await callService("api/print/elencoPdf", param);
                if(outputPdf) {
                    this.filePdf = outputPdf.fileOutput;
                }
                else this.snackError=true;
                this.loadingPdf=false;
            },
            visualizzaPdf(){
                window.open(this.filePdf);
                this.filePdf='';
            },    
            async elencoExcel() {
                this.loadingExcel=true;
                let param = [this.$store.state.idCircoscrizione];
                let outputExcel = await callService("api/print/elencoExcel", param);
                if (outputExcel) {
                    this.fileExcel = outputExcel.fileOutput;
                }
                else this.snackError=true;
                this.loadingExcel=false; 
            },
            visualizzaExcel(){
                window.open(this.fileExcel);
                this.fileExcel='';
            },
            async save() {
                this.loadingCong=true;
                let paramJson = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.oratore.codice,
                    token: this.$store.state.oratore.token,
                    idCircoscrizione: this.$store.state.idCircoscrizione,
                    congregazione: this.congregazione,
                    prenotazione: this.congregazione.prenotazione
                };
                let congregazione = await callPost('api/congregazione/saveCongregazione', paramJson);
                
                this.snackSuccess = (congregazione!=null);
                this.snackError = (congregazione==null);
                this.loadingCong=false;

                if(congregazione) {
                    //ricarico

                    const i=this.congregazioniEstese.findIndex(ce=>ce.congregazione.coId==congregazione.coId);
                    if(i>-1) {
                        this.congregazioniEstese[i].congregazione = congregazione;
                    }
                    else {
                        this.loading = true;
                        let param = [this.$store.state.idCircoscrizione];
                        this.congregazioniEstese = await callService("api/congregazione", param);
                        this.loading = false;
                    }
                }
            }, 
        },
        async created() {
            this.loading = true;
            this.nomeCircoscrizione = this.$store.state.circoscrizione.nome;
            let param = [this.$store.state.idCircoscrizione];
            this.congregazioniEstese = await callService("api/congregazione", param);
            this.listaDiscorsi = await callService("api/discorso", param);
            this.loading = false;
            this.SUPERADMIN_CODE = SUPERADMIN_CODE;
            this.prenotazioniAttive = this.$store.state.circoscrizione.prenotazione;
            this.congregazione.prenotazione = this.prenotazioniAttive;
            bus.$on('dialog-update-oratore', this.onUpdateOratore)
        },
        beforeDestroy() {
            bus.$off('dialog-update-oratore', this.onUpdateOratore)
        }
        
    }</script>
