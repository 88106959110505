<template>
    <v-container>
        <template>
            <v-data-table :loading="loading"
                          :headers="headers"
                          :items="circoscrizioniEstese"
                          item-key="codice"
                          no-data-text="Nessuna circoscrizione presente"
                          :items-per-page="8"
                          class="elevation-1"
                          :footer-props="{
                            'items-per-page-text':'Pagina da',
                            'items-per-page-all-text': 'Tutti',
                            'items-per-page-options':[5,8,10,20,-1]
                            }"
                          :search="search"
                          loading-text="Caricamento dati..."
                          no-results-text="Nessuna circoscrizione corrispondente ai criteri di ricerca">
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title><b>Circoscrizioni</b></v-toolbar-title>
                        <v-col cols="5">
                            <v-text-field class="mt-6 ml-4"
                                          v-model="search"
                                          append-icon="mdi-magnify"
                                          label="Ricerca una circoscrizione..."
                                          single-line
                                          outlined
                                          clearable
                                          dense>
                            </v-text-field>
                        </v-col>
                    </v-toolbar>
                </template>
                <template v-slot:progress>
                    <v-progress-linear v-model="progress"></v-progress-linear>
                </template>
                <template v-slot:item.nome="{ item }">
                    <div>
                        <span class="text-subtitle font-weight-medium" v-text="item.nome" />
                        <v-spacer></v-spacer>
                        <span class="font-weight-light" v-text="item.codice" />
                    </div>
                </template>
                <template v-slot:item.testoRicerca="{ item }">
                    <div>
                        <span v-text="item.elencoAdmin" />
                    </div>
                </template>
                <template v-slot:item.seleziona="{ item }">
                    <v-btn @click="selezionaCirc(item)" small color="info">Seleziona</v-btn>
                </template>
            </v-data-table>
        </template>
    </v-container>
</template>

<script>

    import {callService, callPost, euro, gLocalData, gCheckData, gServerData, gDataCalendar, Snack} from '@/modules/utilities.js'
    import { bus } from '@/main.js'

    export default {        
        data: () => ({
             headers: [
                { text: 'Nome', value: 'nome' },
                { text: 'Numero Congregazioni', value: 'numeroCongregazioni', sortable: false },
                { text: 'Admin', value: 'testoRicerca', sortable: false },
                { text: '', value: 'seleziona', sortable: false },
            ],
            circoscrizioniEstese: [],            
            loading:false,
            progress: 0,
            search: ''
        }),
        computed: {
        },
        methods: {
            onCircProgress({ username, progress }) {

                if (this.$store.state.oratore.codice == username) {
                    this.progress = progress;
                }
            },

            async selezionaCirc(item) {
                    this.$store.state.idCircoscrizione=item.codice;
                    let param = [this.$store.state.idCircoscrizione];
                    this.$store.state.circoscrizione = await callService("api/circoscrizione", param);
                    this.$store.state.externalCirc = true;
                    this.$store.state.externalCong = false;
                    this.$router.replace({ name: "congregazioni" });
            }
        },
        async created() {
            this.$updaterHub.$on('circ-progress', this.onCircProgress)

            this.loading = true;
            let param = [];
            this.circoscrizioniEstese = await callService("api/circoscrizione/lista", param);
            this.loading = false;
        },
        beforeDestroy() {
            this.$updaterHub.$off('circ-progress', this.onCircProgress)
        }
        
    }</script>
