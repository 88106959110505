import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr'

export default {

    install(Vue) {
            /*
            const connection = new HubConnectionBuilder()
            .withUrl('/updater-hub')
            .configureLogging(LogLevel.Information)
            .build();
            */
            const connection = new HubConnectionBuilder().withUrl('/updater-hub', {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets
            }).configureLogging(LogLevel.Information)
            .build();

        let startedPromise = null
        function start() {
            startedPromise = connection.start()
                .then(function () {
                    console.info("SignalR OK");
                })
                .catch(err => {
                    console.error('"SignalR KO"', err)
                    return new Promise((resolve, reject) =>
                        setTimeout(() => start().then(resolve).catch(reject), 5000))
                });
            return startedPromise;
        }

        connection.onclose(() => start());

        start();

        const updaterHub = new Vue();
        Vue.prototype.$updaterHub = updaterHub;

        connection.on('CircProgress', (username, progress) => {

            console.info("CircProgress:" + username+' '+progress);

            updaterHub.$emit('circ-progress', { username: username, progress: progress });

        });
        
    }
}
