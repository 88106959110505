import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "../views/Login.vue"
import Programma from "../views/Programma.vue"
import Congregazioni from "../views/Congregazioni.vue"
import Circoscrizioni from "../views/Circoscrizioni.vue"
import Circoscrizione from "../views/Circoscrizione.vue"
import Oratori from "../views/Oratori.vue"
import Schemi from "../views/Schemi.vue"
import Ricerca from "../views/Ricerca.vue"
import Dashboard from "../views/Dashboard.vue"

Vue.use(VueRouter)

export default new VueRouter({
    routes: [        
        {
            path: "/login",
            name: "login",
            component: Login
        },
        {
            path: "/programma",
            name: "programma",
            component: Programma
        },        
        {
            path: "/congregazioni",
            name: "congregazioni",
            component: Congregazioni
        },
        {
            path: "/circoscrizioni",
            name: "circoscrizioni",
            component: Circoscrizioni
        },
        {
            path: "/circoscrizione",
            name: "circoscrizione",
            component: Circoscrizione
        },
        {
            path: "/oratori",
            name: "oratori",
            component: Oratori
        },
        {
            path: "/schemi",
            name: "schemi",
            component: Schemi
        },        
        {
            path: "/ricerca",
            name: "ricerca",
            component: Ricerca
        },
        {
            path: "/dashboard",
            name: "dashboard",
            component: Dashboard
        }
    ],
    mode: 'history'
})