<template>
    <v-container>
        <template>
            <v-data-table :loading="loadingOratori"
                          :headers="headersOratori"
                          :items="oratoriEstesi"
                          :items-per-page="8"
                          loading-text="Caricamento dati..."
                          no-data-text="Nessuno oratore presente"
                          no-results-text="Nessun oratore corrispondente ai criteri di ricerca"
                          :search="search"
                          :footer-props="{
                            'items-per-page-text':'Pagina da',
                            'items-per-page-all-text': 'Tutti',
                            'items-per-page-options':[5,8,10,20,-1]
                            }"
                          class="elevation-1">
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title><b>Oratori {{nomeCircoscrizione}}</b></v-toolbar-title>
                        <v-col cols="5">
                            <v-text-field class="mt-6 ml-4"
                                          v-model="search"
                                          append-icon="mdi-magnify"
                                          label="Ricerca un oratore..."
                                          single-line
                                          outlined
                                          clearable
                                          dense>
                            </v-text-field>
                        </v-col>
                    </v-toolbar>
                </template>
                <template v-slot:item.oratore.cognome="{ item }">
                    <span v-text="item.oratore.nome+' '+item.oratore.cognome" />
                    <v-spacer></v-spacer>
                    <v-chip v-if="item.oratore.isAnziano" class="mr-2" x-small dark color="teal">A</v-chip>
                    <v-chip v-if="item.oratore.isSM" class="mr-2" x-small dark color="teal">SM</v-chip>
                    <v-chip v-if="item.oratore.isAssistente" class="mr-2" x-small dark color="darkgrey">ASSISTENTE</v-chip>
                    <v-chip v-if="item.oratore.isCoordinatore" class="mr-2" x-small dark color="deep-orange darken-4">ADMIN</v-chip>
                    <v-chip v-if="item.oratore.isAdmin" class="mr-2" x-small dark color="orange">APP ADMIN</v-chip>
                </template>
                <template v-slot:item.oratore.telefono="{ item }">
                    <div>
                        <span class="text-subtitle" v-text="item.oratore.telefono" />
                        <br />
                        <span class="font-weight-light" v-text="item.oratore.mail" />
                    </div>
                </template>
                <template v-slot:header.oratore.giorniDaLogin="{ header }">
                    <span v-if="isAdmin">Giorni da Ultimo Accesso</span>
                </template>
                <template v-slot:item.oratore.giorniDaLogin="{ item }">
                    <div v-if="isAdmin">
                        <div v-if="item.oratore.giorniDaLogin>0 && item.oratore.giorniDaLogin<=100">App: <span class="font-weight-light">{{item.oratore.giorniDaLogin}}</span></div>
                        <div v-if="item.oratore.giorniDaLogin==0">App: <span class="font-weight-light">oggi</span></div>
                        <v-spacer></v-spacer>
                        <div v-if="item.oratore.giorniDaLoginWeb>0 && item.oratore.giorniDaLoginWeb<=100">WebApp: <span class="font-weight-light">{{item.oratore.giorniDaLoginWeb}}</span></div>
                        <div v-if="item.oratore.giorniDaLoginWeb==0">WebApp: <span class="font-weight-light">oggi</span></div>
                    </div>
                </template>
                <template v-slot:item.numeroSchemi="{ item }">
                    <div>
                        <span class="text-subtitle" v-if="item.numeroSchemi>1">
                            {{item.numeroSchemi}} schemi
                        </span>
                        <span class="text-subtitle" v-if="item.numeroSchemi==0">
                            nessuno schema
                        </span>
                        <span class="text-subtitle" v-if="item.numeroSchemi==1">
                            1 schema
                        </span>
                    </div>
                </template>
                <template v-slot:item.dettaglio="{ item }">
                    <div v-if="
                                          $store.state.oratore.codice==SUPERADMIN_CODE
                                          ||
                                          ($store.state.oratore._partition==$store.state.idCircoscrizione
                                           && (
                                                $store.state.oratore.isAdmin
                                             || $store.state.oratore.isCoordinatore
                                             || $store.state.oratore.isAssistente
                                             || $store.state.oratore.orId==item.oratore.orId
                                              )
                                          )">
                        <v-icon @click="clickOratore(item)">mdi-magnify</v-icon>
                    </div>
                </template>
            </v-data-table>

            <oratore-dialog v-model="dialogOratore" editOratore="oratore" />

        </template>
    </v-container>
</template>

<script>

    import {callService, callPost, euro, gLocalData, gCheckData, gServerData, gDataCalendar, Snack, SUPERADMIN_CODE} from '@/modules/utilities.js'
    import OratoreDialog from '@/views/components/OratoreDialog.vue'
    import { bus } from '@/main.js'

    export default {
        components:{
            OratoreDialog
        },
        data: () => ({            
            headersOratori: [
                {
                    text: 'Nominativo',
                    align: 'start',
                    value: 'oratore.cognome',
                },
                { text: 'Congregazione', value: 'oratore.congregazione.nome'},

                { text: 'Telefono/Mail', value: 'oratore.telefono', sortable: false },
                { text: 'Schemi', value: 'numeroSchemi', sortable: false },
                { text: '', value: 'oratore.giorniDaLogin', sortable: false },
                { text: '', value: 'dettaglio', sortable: false },
            ],
            oratoriEstesi: [],
            nomeCircoscrizione:'',
            dialogOratore: false,
            oratore: {},
            discorsi: [],
            listaDiscorsi: [],
            loadingOratori:false,
            SUPERADMIN_CODE: '',
            isAdmin: false,
            search: ''  
        }),
        computed: {
        },
        methods: {
            async clickOratore(oratoreEsteso) {
                this.oratore=JSON.parse(JSON.stringify(oratoreEsteso.oratore));
                let param=[this.$store.state.idCircoscrizione,this.oratore.orId];
                let discorsi = await callService("api/oratore/discorsiOratore", param);
                bus.$emit('dialog-show-oratore',this.oratore.congregazione, this.oratore, discorsi, this.listaDiscorsi);
            },
            async onUpdateOratore({orId,coId}) {
                const i = this.oratoriEstesi.findIndex(o => o.oratore.orId == orId);
                let oratoreUpdate = this.oratoriEstesi[i];
                let param = [this.$store.state.idCircoscrizione,false,coId,orId];
                let oraExt = await callService("api/oratore/congregazioneOratore", param);
                this.oratoriEstesi.splice(i, 1, oraExt[0]);
            }
        },
        async created() {
            this.loadingOratori = true;
            let param = [this.$store.state.idCircoscrizione];
            this.nomeCircoscrizione = this.$store.state.circoscrizione.nome;
            this.listaDiscorsi = await callService("api/discorso", param);
            param = [this.$store.state.idCircoscrizione,false];
            this.oratoriEstesi = await callService("api/oratore/congregazioneOratore", param);
            this.loadingOratori = false;
            this.SUPERADMIN_CODE = SUPERADMIN_CODE;
            this.isAdmin =this.$store.state.oratore.codice==SUPERADMIN_CODE || this.$store.state.oratore.isAdmin;
            bus.$on('dialog-update-oratore', this.onUpdateOratore)
        },
        beforeDestroy() {
            bus.$off('dialog-update-oratore', this.onUpdateOratore)
        }
        
    }</script>
