<template>
    <v-dialog v-model="dialog" :valid="valid" persistent max-width="800px">
        <v-card class="elevation-24 rounded-lg">
            <v-card-title></v-card-title>
            <v-card-text>
                <v-form :disabled="!updateEnabled" v-model="valid" ref="formProgramma">
                    <v-row dense>
                        <v-col cols="3">
                            <v-text-field v-model="dataLocale"
                                          outlined
                                          readonly
                                          hide-details
                                          label="Data"
                                          dense>
                            </v-text-field>
                        </v-col>
                        <v-col cols="6" align="center">
                            <span class="text-h6">{{titoloDialog}}</span>
                            <v-list-item-subtitle v-if="!nuovo && interno && programma.isProvvisorio">in attesa di conferma da parte di {{programma.congregazioneEsterna.coordinatore.nominativo}}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="!nuovo && !interno && programma.isProvvisorio">(discorso da confermare)</v-list-item-subtitle>
                        </v-col>
                        <v-col align="right" cols="3">
                            <v-btn v-if="programma.isStandard && nuovo"
                                   color="success"
                                   @click="azzeraFiltri">
                                Azzera Filtri
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-radio-group
                                       class="ml-2 mr-12"
                                       v-model="tipoEvento"
                                       @change="changeTipoEvento"
                                       >
                            <v-radio :readonly="!nuovo"
                                     :label="hintSWStandard"
                                     value="1">
                            </v-radio>
                            <v-radio :readonly="!nuovo"
                                     :label="hintSWExtra"
                                     value="2">
                            </v-radio>
                        </v-radio-group>
                        <v-radio-group
                                     class="ml-12"
                                     v-model="tipoEvento"
                                     @change="changeTipoEvento">
                            <v-radio :readonly="!nuovo"
                                     color="red darken-3"
                                     label="Evento speciale"
                                     value="3">
                            </v-radio>
                            <v-radio :readonly="!nuovo"
                                     color="red darken-3"
                                     label="Assemblea/Congresso"
                                     value="4">
                            </v-radio>
                            <v-radio :readonly="!nuovo"
                                     color="red darken-3"
                                     label="Visita del sorvegliante"
                                     value="5">
                            </v-radio>
                        </v-radio-group>
                    </v-row>
                    <v-row dense>
                        <v-col>
                            <v-list-item-subtitle v-if="interno && nuovo && programma.isStandard" class="text-caption">
                                E' possibile selezionare a scelta uno dei tre campi. Gli altri saranno filtrati di conseguenza
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-if="!interno && nuovo && programma.isStandard" class="text-caption">
                                Selezionare nell'ordine prima la Congregazione esterna, poi Oratore e Schema
                            </v-list-item-subtitle>
                           </v-col>
                    </v-row>
                    <v-row dense v-if="nuovo && programma.isStandard">
                        <v-col cols="10">
                            <v-autocomplete :readonly="!nuovo || lockCong"
                                            v-model="idCongregazione"
                                            :items="congregazioni"
                                            item-key="coId"
                                            item-value="coId"
                                            item-text="nomeCompleto"
                                            outlined
                                            :hint="hintCongregazioni"
                                            persistent-hint
                                            dense
                                            :rules="[v=>!!v || 'congregazione obbligatoria']"
                                            @change="changeCong"
                                            no-data-text="Nessuno congregazione corrispodende ai criteri di ricerca"
                                            placeholder="Ricerca una congregazione"
                                            :label="interno ? 'Congregazione di provenienza':'Congregazione esterna'">
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="!nuovo && programma.isStandard">
                        <v-col cols="10">
                            <v-text-field v-if="interno && programma.congregazioneEsterna"
                                          v-model="programma.congregazioneEsterna.nome"
                                          outlined
                                          dense
                                          readonly
                                          :hint="hintCongregazioni"
                                          persistent-hint
                                          :label="interno ? 'Congregazione di provenienza':'Congregazione esterna'">
                            </v-text-field>
                            <v-text-field v-else
                                          v-model="nomeCompleto"
                                          outlined
                                          dense
                                          readonly
                                          :hint="hintCongregazioni"
                                          persistent-hint
                                          :label="interno ? 'Congregazione di provenienza':'Congregazione esterna'">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="programma.isExtra">
                        <v-col cols="10">
                            <v-text-field v-model="programma.congregazioneExtra"
                                          outlined
                                          dense
                                          hint="digita manualmente il nome della congregazione"
                                          persistent-hint
                                          :rules="[v=>!!v || 'congregazione obbligatoria']"
                                          placeholder="Congregazione non in elenco"
                                          :label="interno ? 'Congregazione di provenienza':'Congregazione esterna'">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="nuovo && (programma.isStandard || (!interno && programma.isExtra))">
                        <v-col cols="10">
                            <v-autocomplete :readonly="!nuovo || lockOra"
                                            v-model="idOratore"
                                            :items="oratoriDataFiltrati"
                                            item-key="oratore.orId"
                                            item-value="oratore.orId"
                                            item-text="oratore.nominativo"
                                            :hint="hintOratori"
                                            persistent-hint
                                            :rules="[v=>!!v || 'oratore obbligatorio']"
                                            outlined
                                            dense
                                            @change="changeOra"
                                            no-data-text="Nessuno oratore corrispodende ai criteri di ricerca"
                                            placeholder="Ricerca un oratore"
                                            label="Oratore">
                                <template v-slot:item="{item}">
                                    <template>
                                        <v-list-item-content>
                                            <v-list-item-title v-if="interno" v-html="item.oratore.nominativo+' ('+item.oratore.congregazione.nome+')'"></v-list-item-title>
                                            <v-list-item-title v-if="!interno" v-html="item.oratore.nominativo"></v-list-item-title>
                                            <v-list-item-subtitle v-if="item.congregazioneEsterna!='-'" v-html="'Ultima uscita: '+item.congregazioneEsterna+' '+item.dataNumero"></v-list-item-subtitle>
                                            <v-list-item-subtitle v-if="interno && item.dataNumeroInterno!='-'" v-html="'Ultima discorso a : '+item.congregazione.nome+' '+item.dataNumeroInterno"></v-list-item-subtitle>
                                            <v-list-item-subtitle class="orange--text" v-if="item.indisponibile && !item.indisponibileSecco" v-html="item.motivazione"></v-list-item-subtitle>
                                            <v-list-item-subtitle class="red--text" v-if="item.indisponibileSecco" v-html="item.motivazione"></v-list-item-subtitle>
     
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="2">
                            <v-switch v-if="nuovo"
                                      v-model="disponibili"
                                      class="mt-0"
                                      inset
                                      @change="filtra"
                                      hint="mostra solo oratori disponibili"
                                      persistent-hint>
                            </v-switch>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="!nuovo && (programma.isStandard || (programma.isExtra && !interno))">
                        <v-col cols="10">
                            <v-text-field v-model="programma.oratore.nominativo"
                                          outlined
                                          dense
                                          readonly
                                          :hint="hintOratori"
                                          persistent-hint
                                          label="Oratore">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="programma.isExtra && interno">
                        <v-col cols="10">
                            <v-text-field v-model="programma.oratoreExtra"
                                          outlined
                                          dense
                                          hint="digita manualmente il nome dell'oratore"
                                          :rules="[v=>!!v || 'oratore obbligatorio']"
                                          persistent-hint
                                          placeholder="Oratore non in elenco"
                                          label="Oratore">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="programma.isStandard || programma.isExtra">
                        <v-col cols="10">
                            <v-autocomplete :readonly="nuovo && lockSchema"
                                            v-model="idDiscorso"
                                            :items="listaDiscorsiFiltrati"
                                            item-key="discorso.id"
                                            item-value="discorso.id"
                                            item-text="testoRicerca"
                                            :hint="hintDiscorsi"
                                            persistent-hint
                                            outlined
                                            dense
                                            :rules="[v=>!!v || 'schema obbligatorio']"
                                            @change="changeSchema"
                                            no-data-text="Nessuno schema corrispodende ai criteri di ricerca"
                                            label="Schema"
                                            placheholder="Ricerca uno schema per numero o parole">
                                <template v-slot:item="{item}">
                                    <v-list-item-content>
                                        <v-list-item-title>{{item.discorso.tema}}</v-list-item-title>
                                        <v-list-item-subtitle v-if="item.discorso.diId<1000" v-html="'Schema '+item.discorso.diId"></v-list-item-subtitle>
                                        <v-list-item-subtitle v-if="item.ultimaData" v-html="'Ultima volta a '+ nomeCongregazione +': '+localData(item.ultimaData)+' '+item.ultimoOratore"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="2">
                            <v-switch v-if="nuovo && interno" v-model="ultimoAnno"
                                      class="mt-0"
                                      inset
                                      @change="filtra"
                                      hint="mostra solo schemi non svolti nell'ultimo anno"
                                      persistent-hint>
                            </v-switch>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="2">
                            <v-text-field v-model="programma.cantico"
                                          outlined
                                          dense      
                                          :rules="[v=>!v || (/^[0-9]$|^[1-9][0-9]$|^[1][0-4][0-9]$|^[1][5][0-1]$/.test(v)) || '[1-151]']"
                                          persistent-hint
                                          label="Cantico">
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn :class="nuovo && interno && prenotazione ? 'mr-2':''" v-if="nuovo || programma.isStandard || programma.isExtra" :disabled="!valid || !updateEnabled" color="error" @click="salva">{{testoSave}}</v-btn>
                <v-tooltip top v-if="nuovo && interno && programma.isStandard && prenotazione && futuro">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn :disabled="!valid || !updateEnabled || !prenotazioniCong"
                               color="warning"
                               v-on="on"
                               @click="prenota">Prenota</v-btn>
                    </template>
                    <span>Invia richiesta all'Admin {{prenotazioneAdmin}}</span>
                </v-tooltip>

                <v-btn color="warning" :loading="loading" @click="dialog = false">Chiudi</v-btn>
                <v-btn v-if="!eliminaOk && !nuovo && interno && (programma.isStandard || programma.isExtra) && !filePdf" color="success" :loading="loadingPdf" @click="promemoriaPdf">Crea Promemoria</v-btn>
                <v-btn v-if="!eliminaOk && !nuovo && interno && (programma.isStandard || programma.isExtra) &&  filePdf" color="success" @click="visualizzaPdf">Apri Promemoria</v-btn>
                <v-spacer></v-spacer>
                <v-btn v-if="!nuovo && !interno && programma.isProvvisorio" :disabled="!updateEnabled" color="success" @click="conferma">Conferma</v-btn>
                <v-btn v-if="!nuovo" :disabled="!updateEnabled" color="error" @click="elimina">Elimina</v-btn>
            </v-card-actions>           
        </v-card>
        <v-snackbar v-model="snackSuccess"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackInfo"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Credenziali copiate in memoria.
        </v-snackbar>
        <v-snackbar v-model="snackError"
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel"
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote"
                    timeout="2000"
                    color="info">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>
    </v-dialog>
</template>

<script>
    import {STD_PASSWORD, SUPERADMIN_CODE, callService, callPost, euro, gLocalData, gCheckData, gServerData, gDataCalendar, Snack} from '@/modules/utilities.js'

    import { bus } from '@/main.js'

    export default {
        data() {
            return {
                dialog: false,
                programma: {},
                dataLocale: null,
                congregazioni: [],
                oratoriData: [],
                oratoriDataFiltrati:[],
                idCongregazione: '',
                nomeCongregazione: '',
                nomeCompleto:'',
                valid: false,
                listaDiscorsi: [],
                listaDiscorsiFiltrati:[],
                idDiscorso: '',
                idOratore: [],
                snackInfo: false,
                snackSuccess: false,
                snackError: false,
                snackCancel: false,
                snackRemote: false,
                SUPERADMIN_CODE: 0,
                ultimoAnno: true,
                disponibili: true,
                interno: true,
                loading: false,
                nuovo: false,
                updateEnabled: true,
                lockCong: false,
                lockOra: false,
                lockSchema: false,
                loadingPdf: false,
                filePdf: false,
                prenotazione: false,
                prenotazioniCong: false,
                prenotazioneAdmin: '',
                eliminaOk: false,
                futuro: false,
                tipoEvento: 1
            }
        },
        methods: {
            async promemoriaPdf() {
                this.loadingPdf=true;
                let param = [this.$store.state.idCircoscrizione, this.$store.state.idCongregazione,this.programma.id];
                let outputPdf = await callService("api/print/promemoriaPdf", param);
                if(outputPdf) {
                    this.filePdf = outputPdf.fileOutput;
                }
                else this.snackError=true;
                this.loadingPdf=false;
            },
            visualizzaPdf(){
                window.open(this.filePdf);
                this.filePdf='';
            },
            localData(v) {
                return gLocalData(v);
            },
            checkData(d) {
                return gCheckData(d);
            },
            serverData(stringDate) {
                return gServerData(stringDate);
            },    
            apiData(stringDate) {
                return gApiData(stringDate);
            },
            async changeTipoEvento() {
                if(this.tipoEvento=="1") {
                    this.programma.isStandard=true;
                    await this.checkStandard();
                }
                else if(this.tipoEvento=="2") {
                    this.programma.isExtra=true;
                    await this.checkExtra();
                }
                else if(this.tipoEvento=="3") {
                    this.programma.isSpeciale=true;
                    await this.checkSpeciale();
                }
                else if(this.tipoEvento=="4") {
                    this.programma.isCongresso=true;
                    await this.checkCongresso();
                }else if(this.tipoEvento=="5") {
                    this.programma.isVisita=true;
                    await this.checkVisita();
                }
            },
            async checkStandard() {                
                this.programma.isExtra = false;
                this.programma.isSpeciale = false;
                this.programma.isCongresso = false;
                this.programma.isVisita = false;
                this.idCongregazione = '';
                this.idDiscorso = '';
                this.idOratore = '';
                this.lockCong = false;
                this.lockOra = false;
                this.lockSchema = false;
                await this.filtra();
                this.$nextTick(() => {
                    this.$refs.formProgramma.resetValidation();
                });
            },
            async checkExtra() {
                this.programma.isStandard = false;
                this.programma.isSpeciale = false;
                this.programma.isCongresso = false;
                this.programma.isVisita = false;
                this.idCongregazione = '';
                this.idDiscorso = '';
                this.idOratore = '';
                this.lockCong = false;
                this.lockOra = false;
                this.lockSchema = false;
                await this.filtra();
                this.$nextTick(() => {
                    this.$refs.formProgramma.resetValidation();
                });
            },
            checkSpeciale() {
                this.programma.isCongresso = false;
                this.programma.isVisita = false;
                this.programma.isStandard = false;
                this.programma.isExtra = false;
            },
            checkCongresso() {
                this.programma.isSpeciale = false;
                this.programma.isVisita = false;
                this.programma.isStandard = false;
                this.programma.isExtra = false;                
            },            
            checkVisita() {
                this.programma.isSpeciale = false;
                this.programma.isCongresso = false;
                this.programma.isStandard = false;
                this.programma.isExtra = false;
            },
            async prenota() {
                await this.salva(null, true)
            },
            async salva(event, provvisorio) {
                this.loading = true;                

                if(this.nuovo) this.programma.isProvvisorio = (provvisorio==true);

                if(!this.programma.discorso) this.programma.discorso={};
                this.programma.discorso.id= this.idDiscorso;
                
                if(!this.programma.congregazione) this.programma.congregazione={};
                this.programma.congregazione.coId=
                       this.interno
                       || this.programma.isVisita
                       || this.programma.isCongresso
                       || this.programma.isSpeciale
                       ? this.$store.state.idCongregazione : this.idCongregazione;
                               
                if(!this.programma.congregazioneEsterna) this.programma.congregazioneEsterna={};
                this.programma.congregazioneEsterna.coId = !this.interno ? this.$store.state.idCongregazione : this.idCongregazione;
                if(this.programma.congregazioneEsterna.coId == this.programma.congregazione.coId)
                    this.programma.congregazioneEsterna=null;

                if(!this.programma.oratore) this.programma.oratore={};
                this.programma.oratore.orId= this.idOratore;                

                let service = '';
                if (this.nuovo)
                    service = "salva";
                if (!this.nuovo && this.programma.isStandard)
                    service = "aggiornaSchema";
                if (!this.nuovo && this.programma.isExtra)
                    service = "aggiorna";

                let paramJson = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.oratore.codice,
                    token: this.$store.state.oratore.token,
                    idCircoscrizione: this.$store.state.idCircoscrizione,
                    programma: this.programma,
                    interno: this.interno
                };
                let programma = await callPost('api/programma/' + service, paramJson);
                this.snackSuccess = programma;
                this.snackError = !programma;
                this.updateEnabled = false;
                this.loading = false;

                if(programma) {

                    if (this.nuovo)
                    bus.$emit('add-programma', programma);
                    if (!this.nuovo && this.programma.isStandard)
                        bus.$emit('update-programma', programma);
                    if (!this.nuovo && this.programma.isExtra)
                        bus.$emit('update-programma', programma);
               }                             
                
            }, 
            async elimina() {
                this.loading = true;
                let paramJson = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.oratore.codice,
                    token: this.$store.state.oratore.token,
                    idCircoscrizione: this.$store.state.idCircoscrizione,
                    programma: this.programma,
                    interno: this.interno
                };
                let esito = await callPost('api/programma/elimina', paramJson);

                this.snackSuccess = esito;
                this.snackError = !esito;
                this.updateEnabled = false;
                this.loading = false;
                if(esito) {
                    this.eliminaOk = true;
                    bus.$emit('remove-programma', this.programma.id);
                }                
            },
            async conferma() {
                this.loading = true;
                let paramJson = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.oratore.codice,
                    token: this.$store.state.oratore.token,
                    idCircoscrizione: this.$store.state.idCircoscrizione,
                    programma: this.programma,
                };
                let programma = await callPost('api/programma/conferma', paramJson);

                this.snackSuccess = programma;
                this.snackError = !programma;
                this.updateEnabled = false;
                this.loading = false;
                if(programma) {
                    this.programma.isProvvisorio=false;
                    bus.$emit('update-programma', programma);
                }
            },
            azzeraFiltri(){                
                this.idCongregazione='';
                this.idOratore='';
                this.idDiscorso='';
                this.disponibili=true;
                this.ultimoAnno = true;
                this.lockCong = false;
                this.lockOra = false;
                this.lockSchema = false;               
                bus.$emit('dialog-show-programma', this.programmaOrig, this.congregazioni, this.interno, this.nuovo);
            },
            async changeSchema(item) {
                let discorsoInfo = this.listaDiscorsiFiltrati.find(d => d.discorso.id == this.idDiscorso);
                if (discorsoInfo.discorso.diId == 99999) return;

                if(this.idCongregazione) this.lockCong = true;
                if(this.idOratore) this.lockOra=true;
                await this.filtra();

            },
            async changeCong(coId) {
                if(this.idDiscorso) this.lockSchema = true;
                if (this.idOratore) this.lockOra = true;

                if (!this.interno && this.nuovo) {
                    this.lockOra = false;
                }

                await this.setPrenotazione(coId);                

                await this.filtra();
            },
            async setPrenotazione(coId) {
                if(this.interno && this.prenotazione) {
                    if(coId==this.$store.state.idCongregazione) {
                        this.prenotazioniCong = false;                        
                    }
                    else {
                        let param = [this.$store.state.idCircoscrizione,coId];
                        this.prenotazioniCong  = await callService("api/congregazione/haPrenotazioni", param);
                        if(this.prenotazioniCong) {
                            this.prenotazioneAdmin='';
                            let congregazione = this.congregazioni.find(c => c.coId == coId);
                            if(congregazione.coordinatore){
                                this.prenotazioneAdmin=congregazione.coordinatore.nominativo;
                            }
                            else {
                                this.prenotazioniCong=false;
                            }
                        }
                    }
                }
            },
            async changeOra(orId) {
                if(this.idCongregazione) this.lockCong = true;
                if (this.idDiscorso) this.lockSchema = true;

                if (!this.interno && this.nuovo) {
                    this.lockSchema = false;
                }
                await this.filtra();
            },    
            async filtra() {
                this.loading = true;
                if (!this.idOratore && this.nuovo) {
                    let paramJson = {
                        partition: this.$store.state.partition,
                        username: this.$store.state.oratore.codice,
                        token: this.$store.state.oratore.token,
                        idCircoscrizione: this.$store.state.idCircoscrizione,
                        idDiscorso: this.idDiscorso && this.idDiscorso != 99999 && this.nuovo ? this.idDiscorso : '-1',
                        data: this.programma.data,
                        idCongregazioneAppartenenza: this.interno ? (this.idCongregazione ? this.idCongregazione : '-1') : this.$store.state.idCongregazione,
                        idCongregazioneArrivo: this.interno ? this.$store.state.idCongregazione : (this.idCongregazione ? this.idCongregazione : '-1'),
                        soloDisponibili: this.disponibili && this.nuovo,
                        soloRiserva: false
                    };
                    let oratoriData = await callPost("api/oratore/listaOratoreData", paramJson);
                    this.oratoriDataFiltrati = JSON.parse(JSON.stringify(oratoriData));
                    this.oratoriDataFiltrati = this.oratoriDataFiltrati.map(o => ({ ...o, disabled: o.indisponibileSecco }));
                }

                if (!this.idDiscorso) {    
                    let param = [this.$store.state.idCircoscrizione,this.$store.state.idCongregazione, this.interno, this.nuovo && this.ultimoAnno, this.idOratore ? this.idOratore : '-1'];
                    var t0 = performance.now();
                    let listaDiscorsi = await callService("api/discorso/listaInfo", param);
                    var t1 = performance.now();
                    console.log("listaDiscorsi " + (t1 - t0) + " milliseconds.");
                    this.listaDiscorsiFiltrati = JSON.parse(JSON.stringify(listaDiscorsi));
                    this.listaDiscorsiFiltrati = this.listaDiscorsiFiltrati.map(d => ({ ...d, testoRicerca: d.discorso.numeroDesc + ' - ' + d.discorso.tema }));
                }

                if(this.interno && this.idOratore && !this.idCongregazione) {                    
                    let oratoreData = this.oratoriDataFiltrati.find(o=>o.oratore.orId==this.idOratore);
                    this.idCongregazione = oratoreData.oratore.congregazione.coId;
                    this.lockCong = true
                    this.lockOra = true;
                    await this.setPrenotazione(this.idCongregazione);               
                }
                this.loading=false;
            },            
        },
        computed: {              
              testoSave(){
                if(this.nuovo) return "Salva";
                if(!this.nuovo && this.programma.isStandard)
                    return "Aggiorna Schema";
                if(!this.nuovo && this.programma.isExtra)
                    return "Aggiorna";                
              },
              hintCongregazioni() {
                if(!this.nuovo) return "in fase di modifica è possibile aggiornare solo lo schema e il cantico" ;
                if (this.lockCong) return "";
                return "Congregazioni della circoscrizione"; 
              },
              hintOratori() {
                if(!this.nuovo) return "in fase di modifica è possibile aggiornare solo lo schema e il cantico" ;

                if (this.interno) {
                    if (this.lockOra) return "";
                    if (this.idCongregazione && !this.idDiscorso) {
                        let congregazione = this.congregazioni.find(c => c.coId == this.idCongregazione);
                        if (congregazione)
                            return "oratori di " + congregazione.nome;
                    }
                    else if (!this.idCongregazione && this.idDiscorso) {
                        let discorsoInfo = this.listaDiscorsiFiltrati.find(d => d.discorso.id == this.idDiscorso);
                        if (discorsoInfo)
                            return "oratori con schema " + discorsoInfo.discorso.diId;;
                    }
                    else if (this.idCongregazione && this.idDiscorso && (!this.idOratore || (this.idOratore && !this.lockOra))) {
                        let congregazione = this.congregazioni.find(c => c.coId == this.idCongregazione);
                        let discorsoInfo = this.listaDiscorsiFiltrati.find(d => d.discorso.id == this.idDiscorso);
                        if (discorsoInfo.discorso.diId == 99999)
                            return "oratori di " + congregazione.nome ;
                        else
                            return "oratori di " + congregazione.nome +" con schema " + discorsoInfo.discorso.diId;
                    }
                    else 

                    return "oratori della circoscrizione";
                }
                else {
                    return "oratori di " + this.$store.state.congregazioneCorrente.nome;
                }
                
            },
            hintDiscorsi() {
                if (this.lockSchema) return "";
                if(this.idOratore && (!this.interno || (this.interno && this.programma.isStandard))) {
                    let oratoreData = this.oratoriDataFiltrati.find(o=>o.oratore.orId==this.idOratore);
                    if (oratoreData)
                        return "elenco schemi di " + oratoreData.oratore.nominativo;
                }
                return "elenco schemi";
            },
            titoloDialog() {
                if (this.interno) return "Programma Interno";
                else return "Programma Esterno";
            },
            hintSWStandard() {
                if (this.interno) return "discorso interno standard";
                else return "discorso esterno standard";
            },
            hintSWExtra() {
                if (this.interno) return "discorso interno oratore non in elenco";
                else return "discorso esterno congregazione non in elenco"; 
            }
        },
        async created() {
            this.SUPERADMIN_CODE = SUPERADMIN_CODE;
            var vm = this;
            bus.$on('dialog-show-programma', async function (programma, congregazioni, interno, nuovo, futuro) {
                vm.updateEnabled=false;
                if(vm.$refs.formProgramma) vm.$refs.formProgramma.resetValidation();
                vm.interno = interno;
                vm.nuovo = nuovo;
                vm.tipoEvento =
                    vm.nuovo ||  programma.isStandard ? "1" :
                    (programma.isExtra ? "2" :
                    (programma.isSpeciale ? "3" :
                    (programma.isCongresso ? "4" : "5" ))); 

                vm.futuro = futuro;
                vm.lockCong = false;
                vm.lockOra = false;
                vm.lockSchema = false;
                vm.prenotazioniCong = false;
                vm.prenotazione = vm.$store.state.circoscrizione.prenotazione;
                if(vm.prenotazione) {
                    let param = [vm.$store.state.idCircoscrizione,vm.$store.state.idCongregazione];
                    vm.prenotazione = await callService("api/congregazione/haPrenotazioni", param);            
                }

                vm.ultimoAnno=nuovo;
                vm.disponibili=nuovo;
                vm.programmaOrig = JSON.parse(JSON.stringify(programma));
                vm.congregazioni = congregazioni;
                if(interno) vm.nomeCongregazione = programma.congregazione.nome;
                if(programma.cantico==0) programma.cantico="";
                vm.programma = programma;
                vm.dataLocale = JSON.parse(JSON.stringify(vm.localData(programma.data)));
                if(interno) {
                    congregazioni = congregazioni.map(c => ({ ...c, nomeCompleto: c.nome }));
                    if(programma.congregazione) vm.nomeCompleto = programma.congregazione.nome;
                }
                else {
                    congregazioni = congregazioni.map(c => ({ ...c, nomeCompleto: c.nome + (c.giorno ? ' ('+c.giorno+' '+c.ora+')':'' ) }));
                    if(programma.congregazione) vm.nomeCompleto = programma.congregazione.nome + (programma.congregazione.giorno ? ' ('+programma.congregazione.giorno+' '+programma.congregazione.ora+')':'' );
                }
                vm.congregazioni = congregazioni;
                vm.idOratore = '';
                vm.idDiscorso = '';
                vm.idCongregazione = '';

                if(!nuovo && (programma.isVisita || programma.isCongresso || programma.isSpeciale)) {
                    vm.updateEnabled=
                        vm.$store.state.oratore.isAdmin
                        ||
                        ((vm.$store.state.oratore.isCoordinatore || vm.$store.state.oratore.isAssistente)
                        && vm.$store.state.oratore.congregazione.coId==vm.$store.state.congregazioneCorrente.coId);
                    vm.dialog = true;
                    return;
                }

                vm.loading = true;
                vm.dialog = true;
                if(vm.nuovo) await vm.filtra();
                if (vm.interno && programma.congregazioneEsterna) {
                    vm.idCongregazione = programma.congregazioneEsterna.coId;
                }
                else if(vm.interno && programma.congregazione) {
                    vm.idCongregazione = programma.congregazione.coId;
                }
                else if(!vm.interno && programma.congregazione) {
                    vm.idCongregazione = programma.congregazione.coId;
                }
                else vm.idCongregazione='';

                if (programma.oratore) {
                    vm.idOratore = programma.oratore.orId;
                }
                else vm.idOratore = '';

                if (!vm.nuovo) {
                    await vm.filtra();
                }

                if (programma.discorso) {
                    vm.idDiscorso = programma.discorso.id;
                }
                else vm.idDiscorso = '';

                if (!vm.interno && vm.nuovo) {
                    vm.lockOra = true;
                    vm.lockSchema = true;
                }

                vm.loading = false;

                vm.$nextTick(() => {
                    vm.$refs.formProgramma.resetValidation();
                    vm.updateEnabled=
                        vm.$store.state.oratore.isAdmin
                        ||
                        ((vm.$store.state.oratore.isCoordinatore || vm.$store.state.oratore.isAssistente)
                        && vm.$store.state.oratore.congregazione.coId==vm.$store.state.congregazioneCorrente.coId);
                    
                });

            });            
        },
        beforeDestroy() {
            bus.$off('dialog-show-programma');
        }
    }
</script>