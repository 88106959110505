<template>
    <v-container>
        <template>
            <v-data-table :loading="loading"
                          :headers="headers"
                          :items="schemiEstesi"
                          :expanded.sync="expanded"
                          item-key="discorso.id"
                          :show-expand="showExpand"
                          :items-per-page="8"
                          loading-text="Caricamento dati..."
                          no-data-text="Nessuno schema presente"
                          no-results-text="Nessuno schema corrispondente ai criteri di ricerca"
                          :single-expand="true"
                          @item-expanded="expandSchema"
                          :footer-props="{
                            'items-per-page-text':'Pagina da',
                            'items-per-page-all-text': 'Tutti',
                            'items-per-page-options':[5,8,10,20,-1]
                            }"
                          :search="search"
                          class="elevation-1">
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title><b>Schemi</b></v-toolbar-title>
                        <v-col cols="5">
                            <v-text-field class="mt-6 ml-4"
                                          v-model="search"
                                          append-icon="mdi-magnify"
                                          label="Ricerca uno schema..."
                                          single-line
                                          outlined
                                          clearable
                                          dense>
                            </v-text-field>
                        </v-col>
                    </v-toolbar>
                </template>
                <template v-slot:item.discorso.tema="{ item }">
                    <v-row>
                        <span>{{item.discorso.tema}}</span>
                    </v-row>
                    <v-row v-if="item.discorso.isOscurato">
                        <b class="red--text">(al momento non disponibile)</b>
                    </v-row>
                </template>
                <template v-slot:item.dettaglio="{ item }">
                    <div v-if="$store.state.oratore.codice==SUPERADMIN_CODE || $store.state.circoscrizione.lingua">
                        <v-icon @click="clickSchema(item)">mdi-magnify</v-icon>
                    </div>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td></td>
                    <td colspan="2">
                        <v-card>
                            <v-data-table class="my-2"
                                          :loading="loadingOratori"
                                          :headers="headersOratori"
                                          :items="oratori"
                                          :items-per-page="100"
                                          loading-text="Caricamento dati..."
                                          no-data-text="Nessun oratore presente"
                                          hide-default-header
                                          hide-default-footer>
                                <template v-slot:item.cognome="{ item }">
                                    <span v-text="item.nome+' '+item.cognome" />
                                    <v-spacer></v-spacer>
                                    <v-chip v-if="item.isAnziano" class="mr-2" x-small dark color="teal">A</v-chip>
                                    <v-chip v-if="item.isSM" class="mr-2" x-small dark color="teal">SM</v-chip>
                                    <v-chip v-if="item.isAssistente" class="mr-2" x-small dark color="darkgrey">ASSISTENTE</v-chip>
                                    <v-chip v-if="item.isCoordinatore" class="mr-2" x-small dark color="deep-orange darken-4">ADMIN</v-chip>
                                    <v-chip v-if="item.isAdmin" class="mr-2" x-small dark color="orange">APP ADMIN</v-chip>
                                </template>
                                <template v-slot:item.telefono="{ item }">
                                    <div>
                                        <span class="text-subtitle" v-text="item.telefono" />
                                        <br />
                                        <span class="font-weight-light" v-text="item.mail" />
                                    </div>
                                </template>
                            </v-data-table>
                        </v-card>
                    </td>
                </template>
            </v-data-table>
            <template>
                <v-dialog v-model="dialog" persistent max-width="650px">
                    <v-card>
                        <v-card-title></v-card-title>
                        <v-card-text>
                            <v-form v-model="valid" ref="formSchema">
                                <v-row>
                                    <v-col cols="3">
                                        <v-text-field v-model="discorso.diId"
                                                      outlined
                                                      disabled
                                                      label="Numero Schema"
                                                      :rules="[v=> !!v || 'campo obbligatorio']"
                                                      dense>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-checkbox color="red" v-model="discorso.isOscurato"
                                                    outlined
                                                    hint="oscurato"
                                                    persistent-hint
                                                    dense>
                                        </v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field v-model="discorso.tema"
                                                      outlined
                                                      label="Tema"
                                                      :rules="[v=> !!v || 'campo obbligatorio']"
                                                      dense>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn :loading="loadingSchema" :disabled="!valid" color="error" @click="save">Salva</v-btn>
                            <v-btn color="warning" @click.stop="dialog = false">Chiudi</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>

            <v-snackbar v-model="snackSuccess"
                        timeout="2000"
                        color="success">
                <v-icon dark>
                    mdi-checkbox-marked-circle
                </v-icon>
                Operazione eseguita con successo.
            </v-snackbar>
            <v-snackbar v-model="snackInfo"
                        timeout="2000"
                        color="success">
                <v-icon dark>
                    mdi-checkbox-marked-circle
                </v-icon>
                Credenziali copiate in memoria.
            </v-snackbar>
            <v-snackbar v-model="snackError"
                        timeout="2000"
                        color="error">
                <v-icon dark>
                    mdi-alert-circle
                </v-icon>
                Errore durante l'esecuzione dell'operazione.
            </v-snackbar>
            <v-snackbar v-model="snackCancel"
                        timeout="2000"
                        color="warning">
                <v-icon dark>
                    mdi-alert-circle
                </v-icon>
                Aggiornamento non eseguito.
            </v-snackbar>
            <v-snackbar v-model="snackRemote"
                        timeout="2000"
                        color="info">
                <v-icon dark>
                    mdi-alert-circle
                </v-icon>
                Aggiornamento remoto.
            </v-snackbar>
        </template>
    </v-container>
</template>

<script>

    import {callService, callPost, euro, gLocalData, gCheckData, gServerData, gDataCalendar, Snack, SUPERADMIN_CODE} from '@/modules/utilities.js'
    import { bus } from '@/main.js'

    export default {        
        data: () => ({
             headers: [
                { text: '', value: 'data-table-expand' },
                { text: 'Numero', value: 'discorso.diId' },
                { text: 'Tema', value: 'discorso.tema',sortable:false },
                { text: 'Numero Oratori', value: 'numeroOratori', sortable: false },
                { text: '', value: 'dettaglio', sortable: false },
            ],
            headersOratori: [
                {
                    text: 'Nominativo',
                    align: 'start',
                    value: 'cognome',
                },
                { text: 'Congregazione', value: 'congregazione.nome', sortable: false },
                { text: 'Telefono/Mail', value: 'telefono', sortable: false },
               ],
            schemiEstesi: [],
            oratori: [],
            expanded: [],
            nomeCircoscrizione:'',
            loadingOratori: false,
            loadingSchema: false,
            loading: false,
            SUPERADMIN_CODE: '',
            search:'' ,
            showExpand: false,
            discorso: {},
            dialog: false,
            snackInfo: false,
            snackSuccess: false,
            snackError: false,
            snackCancel: false,
            snackRemote: false,
            valid: false
        }),
        computed: {
        },
        methods: {     
            async expandSchema({ item, value }) {
                if (value) {
                    this.loadingOratori=true;
                    let param = [this.$store.state.idCircoscrizione, item.discorso.id];
                    let oratoriDiscorso = await callService("api/oratore/oratoriDiscorso", param); 
                    this.oratori = oratoriDiscorso.map(od => od.oratore);    
                    this.loadingOratori=false;
                }
                else this.oratori=[];
            },
            async save() {
                this.loadingSchema= true;
                let paramJson = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.oratore.codice,
                    token: this.$store.state.oratore.token,
                    idCircoscrizione: this.$store.state.idCircoscrizione,
                    discorso: this.discorso,                   
                };
                let discorso = await callPost('api/discorso/saveDiscorso', paramJson);
                
                this.snackCancel= !discorso;
                this.snackSuccess = !this.snackError;
                this.loadingSchema = false;

                if (discorso) {
                    //ricarico

                    const i = this.schemiEstesi.findIndex(se => se.discorso.diId == discorso.diId);
                    if (i > -1) {
                        this.schemiEstesi[i].discorso = discorso;
                    }                    
                }             
            },
            async clickSchema(schemaEsteso) {
                this.discorso = JSON.parse(JSON.stringify(schemaEsteso.discorso));
                this.dialog = true;
            },
        },
        async created() {
            this.loading = true;
            let param = [this.$store.state.idCircoscrizione];
            this.nomeCircoscrizione = this.$store.state.circoscrizione.nome;
            this.schemiEstesi = await callService("api/discorso/schemiEstesi", param);
            this.loading = false;
            this.SUPERADMIN_CODE = SUPERADMIN_CODE;
            this.showExpand =this.$store.state.oratore.codice==SUPERADMIN_CODE
                            ||
                            (this.$store.state.oratore._partition==this.$store.state.idCircoscrizione
                            && (
                                this.$store.state.oratore.isAdmin
                                || this.$store.state.oratore.isCoordinatore
                                || this.$store.state.oratore.isAssistente
                                )
                            );
        },
        beforeDestroy() {
        }
        
    }</script>
