<template>
    <v-app>
        <v-navigation-drawer v-if="logged" persistent
                             :mini-variant="miniVariant"
                             :clipped="clipped"
                             v-model="drawer"
                             enable-resize-watcher
                             fixed
                             app>
            <v-list>
                <v-list-item v-if="this.$store.state.oratore && this.$store.state.oratore.codice==SUPERADMIN_CODE" value="true" to="/circoscrizioni">
                    <v-list-item-action>
                        <v-icon color="pink">mdi-format-columns</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Circoscrizioni</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="this.$store.state.oratore && (this.$store.state.oratore.isAdmin || this.$store.state.oratore.codice==SUPERADMIN_CODE)" value="true" to="/circoscrizione">
                    <v-list-item-action>
                        <v-icon color="blue-grey">mdi-square-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Circoscrizione</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item value="true" to="/congregazioni">
                    <v-list-item-action>
                        <v-icon color="green">mdi-view-grid</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Congregazioni</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="this.$store.state.oratore && (this.$store.state.oratore.codice==SUPERADMIN_CODE || this.$store.state.oratore.isAdmin || this.$store.state.oratore.isCoordinatore || this.$store.state.oratore.isAssistente)" value="true" to="/oratori">
                    <v-list-item-action>
                        <v-icon color="orange">mdi-account-box-multiple-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Oratori</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item value="true" to="/schemi">
                    <v-list-item-action>
                        <v-icon color="info">mdi-file-document-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Schemi</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item :disabled="!correctSelectCong" value="true" to="/programma">
                    <v-list-item-action>
                        <v-icon color="teal">mdi-calendar</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Programma</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item :disabled="!correctSelectCong" value="true" to="/ricerca">
                    <v-list-item-action>
                        <v-icon color="pink">mdi-clipboard-text-search-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Ricerca</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="abilitaDashboard" :disabled="!correctSelectCong" value="true" to="/dashboard">
                    <v-list-item-action>
                        <v-icon color="blue">mdi-monitor-dashboard</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Dashboard</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar v-if="logged" app :clipped-left="clipped" color="darkgrey" dark>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-btn class="d-none d-lg-flex" icon @click.stop="miniVariant = !miniVariant">
                <v-icon v-html="miniVariant ? 'mdi-chevron-right' : 'mdi-chevron-left'"></v-icon>
            </v-btn>
            <v-img class="rounded-lg mr-2" src="@/assets/talk_icon.png"
                   max-height="35"
                   max-width="35">
            </v-img>
            <v-toolbar-title>
                <div v-if="correctSelectCong">
                    Talk - {{this.$store.state.congregazioneCorrente.nome}} - {{this.$store.state.circoscrizione.nome}}
                </div>
                <div v-else>
                    Talk - [selezionare una congregazione] - {{this.$store.state.circoscrizione.nome}}
                </div>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-switch v-model="theme"
                      @click="switchTheme"
                      persistent-hint
                      :hint="theme ? 'scuro':'chiaro'"></v-switch>
            <template>
                <div class="text-center">
                    <v-menu open-on-hover offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-container v-bind="attrs"
                                         v-on="on">
                                <v-list-item-title>{{nominativo}}</v-list-item-title>
                                <v-list-item-subtitle class="text-subtitle grey--text">{{congregazione}}</v-list-item-subtitle>

                            </v-container>
                        </template>

                        <v-list>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>{{nominativo}}</v-list-item-title>
                                    <v-list-item-subtitle>{{congregazione}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-divider></v-divider>

                        <v-list>
                            <v-list-item @click="logout">
                                <v-list-item-action>
                                    <v-icon>mdi-logout</v-icon>
                                </v-list-item-action>
                                <v-list-item-title>Logout</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>

        </v-app-bar>

        <v-main>
            <router-view />
        </v-main>

        <v-footer v-if="logged" app>
            <span class="text-caption grey--text" v-if="$store.state.oratore.isAdmin">Admin - </span>
            <span class="text-caption grey--text">&nbsp;Lpl Soft &nbsp;&copy;&nbsp;{{new Date().getFullYear()}} - Client VRM {{applicationVersion }}</span>
            <v-spacer></v-spacer>
            <span class="text-body-2"><a href="#" style="text-decoration: none;" @click="privacy=true">Privacy e Dati</a></span>
        </v-footer>
        <v-dialog v-model="privacy" persistent max-width="850px">
            <v-card class="elevation-24 rounded-lg">
                <v-card-title>Informativa sulla Privacy e la gestione dei Dati</v-card-title>
                <v-card-text>
                    <br />
                    <v-row class="mx-1">
                        <p><b>Dati: </b>Utilizzando l'app Talk e il portate talkapp.it presti il consenso affinché i tuoi dati siano condivisi dall'amministratore della circoscrizione e dai coordinatori dei discorsi.</p>
                        <p>Nominativo, numero di telefono, indirizzo email ed elenco degli schemi posseduti sono utilizzati dai coordinatori al fine di stilare il programma dei discorsi e agevolarne la gestione. </p>
                        <p>Nessun dato verrà fornito in alcun modo a terzi o utilizzato a fini promozionali o pubblicitari dall'amministratore di sistema.</p>
                        <p><b>Note tecniche: </b> L'app condivide le informazioni tra i vari coordinatori e oratori nella circoscrizione in modo da avere immediata sincronizzazione. A questo scopo i dati sono memorizzati sul cloud di MongoDB (https://www.mongodb.com) che è una soluzione professionale ed efficiente molto utilizzata nell'ingegneria del software. L'intero database è criptato e protetto da password con tutti i criteri di sicurezza più recenti.</p>
                        <p>L’admin della circoscrizione ha comunque la possibilità facendo tap su un tasto nell’app di rimuovere tutti i dati i maniera definitiva.</p>
                        <p><b>Cookies: </b>Sono utilizzati per memorizzare alcune preferenze di visualizzazione del portale (esempio il tema chiaro/scuro). Non viene effettuata alcuna profilazione utente per fini commerciali. </p>
                        <p><b>Costi: </b>L'app è gratuita, non prevede alcun tipo di pubblicità e i costi di sviluppo software, di dominio e hosting e di mantenimento dell'app negli store non saranno mai richiesti agli utenti.</p>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="warning" @click.stop="privacy = false">Ok</v-btn>                 
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>

</template>

<script>

    import { callService, callPost, euro, gLocalData, gCheckData, gServerData, Snack, SUPERADMIN_CODE } from '@/modules/utilities.js'
    import { version } from "/package.json"

    export default {
        data() {
            return {
                applicationVersion: version,
                clipped: true,
                drawer: true,
                miniVariant: false,
                right: true,
                title: 'Talk',
                SUPERADMIN_CODE:'',
                theme: false,
                privacy: false
            }
        },
        name: 'App',
        computed: {
            correctSelectCong() {
                 let ok1= this.$store.state.externalCirc;
                 let ok2= this.$store.state.externalCong;
                return (!ok1 && !ok2) || (ok1 && ok2);
            },
            logged() {
                return this.$store.state.logged
            },
            nominativo() {
                return this.$store.state.oratore.nominativo;
            },
            congregazione() {
                return this.$store.state.oratore.congregazione.nome;
            },
            ruoloUtente() {
                return this.$store.state.oratore.codice;
            },
            abilitaDashboard() {
                return this.$store.state.oratore.codice == SUPERADMIN_CODE ||
                    this.$store.state.oratore.isAdmin ||
                    this.$store.state.oratore.isCoordinatore ||
                    this.$store.state.oratore.isAssistente
            }
        },
        methods: {      
            logout() {
                this.$store.dispatch('logout');
                this.$store.state.oratore= '';
                this.$router.replace({ name: "login" });
            },
            switchTheme() {
                this.$vuetify.theme.dark = this.theme;
                this.$cookie.set('theme', this.theme,  { expires: '1Y' });
            }
        },
        beforeCreate(){
           
        },
        created(){
            this.SUPERADMIN_CODE = SUPERADMIN_CODE;
            let theme = this.$cookie.get('theme')=="true"; //trasformo da string a bool
            this.theme = theme;
            this.$vuetify.theme.dark = theme;
            this.$updaterHub.$on('cliente-changed', this.onClienteChanged);
        },        
        destroyed() {
            this.$updaterHub.$off('cliente-changed', this.onClienteChanged)
        },
        mounted() {
            if (!this.$store.state.logged) {
                this.$router.replace({ name: "login" });
            }
        }
    }</script>

<style>
    table.v-table tbody td {
        font-size: 28px !important;
    }
</style>


