<template>
    <v-app>
        <v-container fill-height fluid style="background-color:gray">
            <v-row align="center"
                   justify="center">
                <v-col v-if="noLock" xl="6" lg="6" md="6" sm="10" xs="10">
                    <v-form ref="form"
                            lazy-validation
                            v-on:keyup.native.enter="checkUtente">
                        <v-card class="elevation-24 rounded-lg">
                            <v-row align="center"
                                   justify="center">
                                <v-col cols="3" class="d-none d-md-flex" align="center">
                                    <v-container class="d-none d-md-flex">
                                        <v-img class="rounded-lg" src="@/assets/talk_login.png"
                                               width="125" aspect-ratio="1"></v-img>                                                   
                                    </v-container>
                                </v-col>
                                <v-col xl="6" lg="6" md="6" sm="10" xs="10" class="mt-12" align="center">
                                    <span class="text-subtitle-1 font-weight-medium">Talk App Web</span>
                                    <v-text-field class="mx-4"
                                                  v-model="username"
                                                  label="Username"
                                                  placeholder="esempio: 12345678-cal01"
                                                  :rules="[v => !!v || 'campo obbligatorio', v =>  /[0-9]-[a-z0-9]/.test(v) ||  'formato errato', v => message!='0' || 'username o password errate']"
                                                  outlined
                                                  dense
                                                  required>
                                    </v-text-field>
                                    <v-text-field class="mx-4"
                                                  v-model="password"
                                                  label="Password"
                                                  :rules="[v => !!v || 'campo obbligatorio']"
                                                  :type="show1 ? 'text' : 'password'"
                                                  placeholder="Inserisci la password"
                                                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                                  @click:append="show1 = !show1"
                                                  outlined
                                                  dense
                                                  required>
                                    </v-text-field>
                                    <v-btn :loading="loading" class="mb-4" depressed @click="checkUtente"
                                           color="warning">
                                        Login
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="8" align="center" offset="2">
                                    <v-progress-linear :color="progressValue==0 ? 'transparent':'info'" :value="progressValue"></v-progress-linear>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="12" align="center">
                                    <span class="text-caption grey--text">Client VRM {{applicationVersion }}</span>
                                    <br/>
                                    <span class="text-caption text--secondary">
                                        Segnala tutti i bug riscontrati a <a href="mailto:luigi.degiacomo@gmail.com">luigi.degiacomo@gmail.com</a>
                                    </span>
                                    <br />
                                    <span class="text-caption text--secondary">
                                        Per accedere alla vecchia versione clicca qui: <a target="_blank" href="https://old.talkapp.it">old.talkapp.it</a>
                                    </span>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-form>
                </v-col>
                <v-col v-if="lock" xl="6" lg="6" md="6" sm="10" xs="10">
                    <v-card class="elevation-24 rounded-lg">
                        <v-row align="center"
                               justify="center">
                            <v-col cols="3" class="d-none d-md-flex" align="center">
                                <v-container class="d-none d-md-flex">
                                    <v-img class="rounded-lg" src="@/assets/talk_login.png"
                                           height="125" width="125"></v-img>
                                </v-container>
                            </v-col>
                            <v-col xl="6" lg="6" md="6" sm="10" xs="10" class="mt-12" align="center">
                                <span class="text-subtitle-1 font-weight-medium">Talk App Web</span>
                                <p class="text-caption text--secondary">
                                    Talk Web App è attualmente in manutenzione.
                                    <br />
                                    Si tratta normalmente di aggiornamenti strutturali e/o prestazionali.
                                    <br />
                                    Il servizio verrà ripristinato al più presto.
                                    <br />
                                    <a href="mailto:luigi.degiacomo@gmail.com">luigi.degiacomo@gmail.com</a>
                                </p>
                            </v-col>
                        </v-row>

                    </v-card>
                </v-col>
            </v-row>
        </v-container>        
    </v-app>
</template>

<script>
    import { callService, callPost} from '@/modules/utilities.js'
    import { version } from "/package.json"

    export default {
        name: 'Login',
        data() {
            return {
                applicationVersion: version,
                username: '',
                codice:'',
                circoscrizione:'',
                show1: false,
                password: '',
                message: '',
                loading: false,
                progressValue: 0,
                lock: false,
                noLock: false
            }
        },
        methods: {
            async checkUtente() {

                this.message = '';

                if (!this.$refs.form.validate()) return;

                this.loading= true;

                const v = this.username.split('-');

                let paramJson={
                        codice: v[0],
                       _partition: v[1],
                        password: this.password,
                        
                };

                this.progressValue='10';

                const oratore = await callPost('api/oratore', paramJson, true);

                this.progressValue='40';

                if(!oratore){                    
                    this.message = '0';
                    this.$refs.form.validate();
                    this.loading = false;
                    this.progressValue = 0;
                }
                else {

                    //imposto lo stato


                    this.$store.state.partition=oratore._partition;
                    this.$store.state.oratore=oratore;
                    this.$store.state.congregazione=oratore.congregazione;
                    this.$store.state.idCircoscrizione=oratore._partition;
                    this.$store.state.idCongregazione = oratore.congregazione.coId;
                    this.$store.state.congregazioneCorrente = oratore.congregazione;

                    let param = [this.$store.state.idCircoscrizione];
                    this.$store.state.circoscrizione = await callService("api/circoscrizione", param);
                    this.progressValue='80';
                    this.progressValue='100';

                    let login=this;  
                    setTimeout(function() {
                        login.$store.dispatch('login');
                        login.$router.replace({ name: "programma" });
                    }, 200);                    
                };
            }
        },
        async created() {
            let param = [];
            this.lock = await callService("api/lockSite", param, true);            
            this.noLock = !this.lock;
        },
    }</script>

<style>
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-transition-delay: 9999s;
        transition-delay: 9999s;
    }
</style>
