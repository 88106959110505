<template>
    <v-dialog v-model="dialog" persistent max-width="900px">
        <v-card class="elevation-24 rounded-lg">
            <v-card-title></v-card-title>
            <v-card-text>
                <v-form :readonly="!modificheAbilitate" v-model="valid" ref="formOratore">
                    <v-row>
                        <v-col cols="6">
                            <v-text-field v-model="oratore.nome"
                                          outlined
                                          label="Nome"
                                          :rules="[v=> !!v || 'campo obbligatorio']"
                                          dense>
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="oratore.cognome"
                                          outlined
                                          label="Cognome"
                                          :rules="[v=> !!v || 'campo obbligatorio']"
                                          dense>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field v-model="oratore.telefono"
                                          outlined
                                          label="Telefono"
                                          dense>
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="oratore.mail"
                                          outlined
                                          label="Indirizzo email"
                                          :rules="[v=>(v==null || v.length==0 ||  /.+@.+\..+/.test(v) ) || 'inserire una mail valida']"
                                          dense>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field v-if="oratore.codice" v-model="oratore.codice+'-'+oratore._partition"
                                          outlined
                                          readonly
                                          append-icon="mdi-content-copy"
                                          @click:append="copiaCredenziali"
                                          hint="cliccare sull'icona per copiare le credenziali"
                                          persistent-hint
                                          label="Codice"
                                          dense>
                            </v-text-field>
                        </v-col>
                        <v-col cols="1" class="pr-0">
                            <v-switch v-model="oratore.isInterno" class="mt-0"
                                      inset
                                      :disabled="!modificheAbilitate || !isAdmin"
                                      hint="Solo interno"
                                      @click="checkInterno"
                                      persistent-hint>
                            </v-switch>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="1" class="px-0">
                            <v-switch v-model="oratore.isSM" class="mt-0"
                                      inset
                                      hint="SM"
                                      :disabled="!modificheAbilitate || !isAdmin"
                                      @click="checkSM"
                                      persistent-hint>
                            </v-switch>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="1" class="px-0">
                            <v-switch v-model="oratore.isAnziano" class="mt-0"
                                      inset
                                      hint="Anziano"
                                      :disabled="!modificheAbilitate || !isAdmin"
                                      @click="checkAnziano"
                                      persistent-hint>
                            </v-switch>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="1" class="px-0">
                            <v-switch v-model="oratore.isAssistente" class="mt-0"
                                      inset
                                      hint="Assistente"
                                      @click="checkAssistente"
                                      :disabled="isCoordinatore || !modificheAbilitate || !isAdmin"
                                      persistent-hint>
                            </v-switch>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="1" class="px-0">
                            <v-switch v-model="oratore.isCoordinatore" class="mt-0"
                                      inset
                                      :hint="hintAdmin"
                                      :disabled="isCoordinatore || !modificheAbilitate || !isAdmin"
                                      @click="checkAdmin"
                                      persistent-hint>
                            </v-switch>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-autocomplete v-model="nuovoSchema"
                                            :items="listaDiscorsi"
                                            item-key="diId"
                                            item-value="diId"
                                            item-text="testoRicerca"
                                            :filter="filterFunction"
                                            dense
                                            hide-details
                                            outlined
                                            @change="aggiungiSchema"
                                            no-data-text="Nessuno schema corrispodende ai criteri di ricerca"
                                            label="Ricerca uno schema per numero o parole">
                                <template v-slot:item="{item}">
                                    <v-list-item-content>
                                        <v-list-item-title v-html="item.tema"></v-list-item-title>
                                        <v-list-item-subtitle v-html="'Schema '+item.diId"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field ref="newSchemi" v-model="nuoviSchemi"
                                          outlined
                                          append-icon="mdi-plus"
                                          @click:append="aggiungiSchemi"
                                          :rules="[v=> v==null || v.length==0 || /^[0-9 ]+$/.test(v) || 'schemi non validi']"
                                          label="Inserisci numeri separati da spazio"
                                          dense>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-data-table ref="tableDiscorsi"
                                          :headers="headers"
                                          :items="discorsi"
                                          item-key="discorso.diId"
                                          dense
                                          :page="page"
                                          :items-per-page="5"
                                          no-data-text="Nessuno schema presente"
                                          no-results-text="Nessuno schema presente"
                                          hide-default-footer
                                          class="elevation-2 class=">
                                <template v-slot:header.arrows="{ header }">
                                    <div align="right">
                                        <span v-text="discorsi.length+' schemi'"></span>
                                        <v-icon :disabled="page==1" @click="page = page>1 ? page-1 : page">mdi-chevron-left</v-icon>
                                        <span>{{page}}/{{Math.ceil(discorsi.length/5)}}</span>
                                        <v-icon :disabled="page>=discorsi.length/5" @click="page = page<discorsi.length/5 ? page=page+1:page">mdi-chevron-right</v-icon>
                                    </div>
                                </template>
                                <template v-slot:item.arrows="{ item }">
                                    <v-icon v-if="modificheAbilitate" @click="clickElimina(item)">mdi-trash-can-outline</v-icon>
                                </template>
                                <template v-slot:item.tipoModifica="{ item }">
                                    <v-icon color="blue" v-if="item.tipoModifica==1">mdi-plus-circle-outline</v-icon>
                                    <v-icon color="red" v-if="item.tipoModifica==2">mdi-minus-circle-outline</v-icon>
                                </template>
                                <template v-slot:item.cantico="{ item }">
                                    <span v-if="item.cantico>0" v-text="item.cantico" />
                                    <span v-if="item.cantico==0">-</span>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn v-if="modificheAbilitate" :disabled="!valid" color="error" @click="save">Salva</v-btn>
                <v-btn color="warning" @click.stop="dialog = false">Chiudi</v-btn>
                <v-spacer></v-spacer>
                <v-btn v-if="isSuperAdmin" color="warning" @click="switchAdminCirc">{{testoAdminCirc}}</v-btn>
            </v-card-actions>
        </v-card>
        <v-snackbar v-model="snackSuccess"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackInfo"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Credenziali copiate in memoria.
        </v-snackbar>
        <v-snackbar v-model="snackError"
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel"
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote"
                    timeout="2000"
                    color="info">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>
    </v-dialog>
</template>

<script>
    import {STD_PASSWORD, SUPERADMIN_CODE, callService, callPost, euro, gLocalData, gCheckData, gServerData, gDataCalendar, Snack} from '@/modules/utilities.js'

    import { bus } from '@/main.js'

    export default {
        data() {
            return {
                dialog: false,
                oratore: {},
                congregazione: {},
                isCoordinatore:false,
                isAssistente:false,
                valid: false,
                discorsi: [],
                listaDiscorsi: [],
                nuoviSchemi: '',
                nuovoSchema: '',
                headers: [
                    { text: '', value: 'tipoModifica',width:'50',sortable: false },
                    { text: 'Schema', value: 'discorso.diId' },
                    { text: 'Tema', value: 'discorso.tema', sortable: false },
                    { text: 'Cantico', value: 'cantico', sortable: false },
                    { text: '', value: 'arrows', width: '200', align: "center", sortable: false },
                ],
                page: 1,
                snackInfo: false,
                snackSuccess: false,
                snackError: false,
                snackCancel: false,
                snackRemote: false,
                SUPERADMIN_CODE: 0
            }
        },
        methods: {
            async save() {
                let paramJson = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.oratore.codice,
                    coId: this.congregazione.coId,
                    token: this.$store.state.oratore.token,
                    idCircoscrizione: this.$store.state.idCircoscrizione,
                    oratore: this.oratore,
                    listaDiscorsi: this.discorsi.filter(d => d.tipoModifica != 2)
                };
                let oratore = await callPost('api/oratore/saveOratore', paramJson);
                
                this.snackSuccess = (oratore!=null);
                this.snackError = (oratore==null);

                if(oratore) {

                    bus.$emit('dialog-update-oratore', {orId: oratore.orId, coId: oratore.congregazione.coId, coIdUpd: oratore.congregazione.coId});
                    if(this.congregazione.coordinatore) {
                        bus.$emit('dialog-update-oratore', {orId: this.congregazione.coordinatore.orId, coId: oratore.congregazione.coId, coIdUpd: null});
                    }

                    //ricarico

                    let param=[this.$store.state.idCircoscrizione,oratore.orId];
                    let discorsi = await callService("api/oratore/discorsiOratore", param);
                    bus.$emit('dialog-show-oratore',this.congregazione, oratore, discorsi, this.listaDiscorsi);
                }              
            },                
            filterFunction(item, queryText) {
                return (                    
                    (item.diId+"").toLowerCase().includes(queryText.toLowerCase()) ||  
                    item.tema.toLowerCase().includes(queryText.toLowerCase())
                  );
            },
            copiaCredenziali() {
                navigator.clipboard.writeText(
                    'username: '+this.oratore.codice+'-'+this.oratore._partition+'\n'+
                    'password: '+(this.oratore.password ? this.oratore.password:STD_PASSWORD)
                );
                this.snackInfo = true;
            },
            aggiungiSchemi() {
                if(!this.$refs.newSchemi.valid) return;
                const schemi = this.nuoviSchemi.split(" ");
                for(let i=0;i<=schemi.length;i++) {
                    this.aggiungiSchema(schemi[i]);
                }
                this.nuoviSchemi='';
            },
            aggiungiSchema(schema) {
                let diId = schema ? schema : this.nuovoSchema;
                if(!diId) return;
                if(this.discorsi.findIndex(d=>d.discorso.diId==diId)>-1) {
                    this.$nextTick(() => {
                        this.nuovoSchema=null;
                    });
                    return;
                }

                let discorso=JSON.parse(JSON.stringify(this.listaDiscorsi.find(d=>d.diId == diId)));

                let oratoreDiscorso = {
                    discorso: discorso,
                    oratore: this.oratore,
                    numero: discorso.diId,
                    nominativo: this.oratore.nominativo,
                    tipoModifica: 1
                }

                this.discorsi.push(oratoreDiscorso);

                this.page = Math.ceil(this.discorsi.length/5);
                this.$nextTick(() => {
                    this.nuovoSchema=null;
                })
            },
            clickElimina(item) {
                if(item.tipoModifica==0) {
                    item.tipoModifica=2;
                }
                else if(item.tipoModifica==1) {
                    this.discorsi.splice(this.discorsi.findIndex(d=>d.diId==item.diId));
                }
                else if(item.tipoModifica==2) {
                    item.tipoModifica=0;
                }
                if (this.page > Math.ceil(this.discorsi.length / 5)) {
                    this.page = Math.ceil(this.discorsi.length / 5);
                }
            },
            checkAdmin() {
                if (this.oratore.isCoordinatore) {
                    this.oratore.isAssistente = false;
                }
            },
            checkAssistente() {
                if (this.oratore.isAssistente) {
                    this.oratore.isCoordinatore = false;
                }
            },
            checkAnziano() {
                if (this.oratore.isAnziano) {
                    this.oratore.isSM = false;
                }
                if (!this.oratore.isAnziano && !this.oratore.isSM) {
                    this.oratore.isInterno = true;
                }
            },
            checkSM() {
                if (this.oratore.isSM) {
                    this.oratore.isAnziano = false;
                }
                if (!this.oratore.isAnziano && !this.oratore.isSM) {
                    this.oratore.isInterno = true;
                }
            },
            checkInterno() {
                if (!this.oratore.isInterno) {
                    if (!this.oratore.isAnziano && !this.oratore.isSM) {
                        this.oratore.isAnziano = true;
                    }
                }
            },
            async switchAdminCirc() {
                let paramJson = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.oratore.codice,
                    token: this.$store.state.oratore.token,
                    idCircoscrizione: this.$store.state.idCircoscrizione,
                    idOratore: this.oratore.orId
                };
                let oratore = await callPost('api/oratore/switchAdmin', paramJson);

                if(oratore) this.oratore.isAdmin =oratore.isAdmin;
                this.snackSuccess = (oratore!=null);
                this.snackError = (oratore==null);

                if(oratore) {

                    bus.$emit('dialog-update-oratore', {orId: oratore.orId, coId: oratore.congregazione.coId, coIdUpd: oratore.congregazione.coId});
                    if(this.congregazione.coordinatore) {
                        bus.$emit('dialog-update-oratore', {orId: this.congregazione.coordinatore.orId, coId: oratore.congregazione.coId, coIdUpd: null});
                    }

                    //ricarico

                    let param=[this.$store.state.idCircoscrizione,oratore.orId];
                    let discorsi = await callService("api/oratore/discorsiOratore", param);
                    bus.$emit('dialog-show-oratore',this.congregazione, oratore, discorsi, this.listaDiscorsi);
                } 
            }
        },
        computed: {
            testoAdminCirc() {
                if(this.oratore.isAdmin) return "Togli App Admin";
                else return "Imposta App Admin";
            },            
            nominativo() {
                return this.oratore.nome + ' ' + this.oratore.cognome;
            },
            hintAdmin() {
                if(!this.isCoordinatore && this.oratore.isCoordinatore && this.congregazione.coordinatore) {
                    return this.congregazione.coordinatore.nominativo + " diventa assistente";
                }
                return "Admin";
            },
            modificheAbilitate() {
                if(this.oratore.orId == "0") return true;
                if(!this.oratore.congregazione) return false;
                let b=false;
                if(this.$store.state.oratore.codice==this.SUPERADMIN_CODE) b = true;
                else if(this.$store.state.oratore.isAdmin) b=true;
                else if(this.$store.state.oratore.congregazione.coId==this.oratore.congregazione.coId && (this.$store.state.oratore.isCoordinatore || this.$store.state.oratore.isAssistente)) b = true;
                else if(this.$store.state.oratore.orId==this.oratore.orId) b = true;

                return b;
            },
            isAdmin() {
                let b=false;
                if(this.$store.state.oratore.codice==this.SUPERADMIN_CODE) b = true;
                else if(this.$store.state.oratore.isAdmin) b = true;
                else if(this.$store.state.oratore.isCoordinatore) b = true;
                else if(this.$store.state.oratore.isAssistente) b = true;

                return b;
            },
            isSuperAdmin() {
                let b=false;
                if(this.$store.state.oratore.codice==this.SUPERADMIN_CODE) b = true;
                return b;
            }
        },
        async created() {
            this.SUPERADMIN_CODE = SUPERADMIN_CODE;

            var vm = this;
            bus.$on('dialog-show-oratore', async function (congregazione, oratore, discorsi, listaDiscorsi) {
                vm.dialog = true;
                vm.oratore = oratore;
                vm.congregazione = congregazione,
                vm.isCoordinatore = oratore.isCoordinatore;
                vm.isAssistente = oratore.isAssistente;
                vm.discorsi = discorsi.map(d => ({...d, tipoModifica: 0}));
                vm.listaDiscorsi = listaDiscorsi;
                vm.listaDiscorsi = vm.listaDiscorsi.map(d => ({ ...d, testoRicerca: d.diId + ' ' + d.tema }));
                vm.$nextTick(() => {
                    if(!vm.$refs.formOratore) return;
                    vm.$refs.formOratore.resetValidation();
                });
            });
            
        }
    }
</script>